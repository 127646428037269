import { Box, styled } from '@mui/material';
import { Colors, PropertyStatuses, ApplicationStatuses } from 'enums';

export const StyledBox = styled(Box)<{
  status: ApplicationStatuses | PropertyStatuses;
}>`
  ${({ status }) => {
    switch (status) {
      case PropertyStatuses.ACTIVE:
        return `
          background: ${Colors.STATUS_COMPLETED};
        `;

      case PropertyStatuses.INACTIVE:
        return `
          background: ${Colors.STATUS_INACTIVE};
        `;

      case ApplicationStatuses.COMPLETED:
        return `
          background: ${Colors.STATUS_COMPLETED};
        `;

      case ApplicationStatuses.REJECTED:
        return `
          background: ${Colors.STATUS_REJECT};
        `;

      case ApplicationStatuses.CANCELED:
        return `
            background: ${Colors.STATUS_CANCELED};
          `;

      case ApplicationStatuses.APPROVED:
        return `
            background: ${Colors.STATUS_ACTIVE};
          `;

      case ApplicationStatuses.EDIT_REQUEST:
        return `
          background: ${Colors.STATUS_EDIT_REQUEST};
        `;

      case ApplicationStatuses.NEW:
        return `
          background: ${Colors.STATUS_NEW};
        `;

      case ApplicationStatuses.PENDING:
        return `
          background: ${Colors.STATUS_PENDING};
        `;

      case ApplicationStatuses.PAID:
        return `
          background: ${Colors.STATUS_PAID};
        `;

      case ApplicationStatuses.USER_ACTION_PENDING:
        return `
          background: ${Colors.STATUS_PENDING};
        `;

      default:
        return `
          background: ${Colors.STATUS_ACTIVE};
      `;
    }
  }}

  font-weight: 700;
  font-size: 10px;
  line-height: 1.6;
  width: 90px;
  height: 24px;
  border-radius: 8px;
  text-align: center;
  padding: 4px 0px;
  color: ${Colors.WHITE};
`;
