import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.5"
      d="M13.3329 26.6666H18.6663C23.6946 26.6666 26.2087 26.6666 27.7708 25.1045C29.3329 23.5424 29.3329 21.0282 29.3329 15.9999C29.3329 15.4107 29.3307 13.856 29.3282 13.3333H2.66659C2.66407 13.856 2.66627 15.4107 2.66627 15.9999C2.66627 21.0282 2.66627 23.5424 4.22836 25.1045C5.79046 26.6666 8.30462 26.6666 13.3329 26.6666H13.3329Z"
      fill="white"
    />
    <path
      d="M13.3265 5.33325H18.6736C23.7149 5.33325 26.2355 5.33325 27.8017 6.82096C28.9293 7.89213 29.245 9.43334 29.3334 11.9999V13.3333H2.66675V11.9999C2.75515 9.43334 3.07088 7.89213 4.1985 6.82096C5.76462 5.33325 8.28526 5.33325 13.3265 5.33325Z"
      fill="white"
    />
    <path
      d="M16.6667 20.3333C16.1144 20.3333 15.6667 20.781 15.6667 21.3333C15.6667 21.8855 16.1144 22.3333 16.6667 22.3333H18.6667C19.219 22.3333 19.6667 21.8855 19.6667 21.3333C19.6667 20.781 19.219 20.3333 18.6667 20.3333H16.6667Z"
      fill="white"
    />
    <path
      d="M8 20.3333C7.44772 20.3333 7 20.781 7 21.3333C7 21.8855 7.44772 22.3333 8 22.3333H13.3333C13.8856 22.3333 14.3333 21.8855 14.3333 21.3333C14.3333 20.781 13.8856 20.3333 13.3333 20.3333H8Z"
      fill="white"
    />
  </svg>
);

export default React.memo(Icon);
