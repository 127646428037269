export function getStringifiedQueryParams(params: any): string {
  const queryParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined) {
      queryParams.set(key, String(value));
    }
  }

  return queryParams.toString();
}
