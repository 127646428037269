import { Box, Stack, styled } from '@mui/material';
import { Colors } from 'enums';

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 80%;
  background-color: ${Colors.DARK_KNIGHT};
  border-radius: 20px;
  margin: 30px 0 5px 56px;

  @media (max-height: 850px) {
    top: 63vh;
    width: 85%;
  }

  @media (min-height: 851px) and (max-height: 950px) {
    top: 60%;
  }

  @media (min-height: 951px) {
    top: 55%;
  }
`;

export const StyledStack = styled(Stack)`
  flex-direction: column;
  width: 520px;
  margin-top: 7vh;
  margin-left: 56px;
`;

export const StyledImg = styled('img')`
  height: 80px;
  width: 80px;
  min-height: 80px;
  margin: 0 auto;
  height: fit-content;
  object-fit: contain;
`;
