enum CheckTypes {
  ASSETS = 'assets',
  BANK_INCOME = 'bank-income',
  PAYSCORE = 'payscore',
  MONITOR = 'monitor',
  TRU_VISION = 'tru-vision',
  SHAREABLE = 'sharable',
  SHAREABLE_PART = 'sharable-no-eviction'
}

const { ASSETS, BANK_INCOME, PAYSCORE, MONITOR, TRU_VISION, SHAREABLE, SHAREABLE_PART } =
  CheckTypes;

export {
  CheckTypes,
  ASSETS,
  BANK_INCOME,
  PAYSCORE,
  MONITOR,
  TRU_VISION,
  SHAREABLE,
  SHAREABLE_PART
};
