import { Box, Dialog, DialogContent, Stack, styled } from '@mui/material';
import { getScrollbarCSS } from 'themes/helpers';

export const StyledDialog = styled(Dialog)`
  backdrop-filter: blur(5px);
  .MuiDialog-paper {
    background: transparent;
  }

  .MuiDialog-root {
    background: transparent;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  background-color: ${({ theme }) => theme.palette.common.dark};
  border: 1px solid ${({ theme }) => theme.palette.common.gold};
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  overflow: hidden;
  border-radius: 20px;

  h3, h6 {
    color: ${({ theme }) => theme.palette.common.white};
`;

export const StyledModalStack = styled(Stack)`
  display: flex;
  flex-direction: row;
  gap: 35px;
`;

export const StyledScrollStack = styled(Stack)`
  overflow-y: auto;
  max-height: 70vh;
  max-width: 70vw;
  ${getScrollbarCSS()};
`;

export const Thumb = styled(Box)`
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.common.dark};
`;

export const StyledTitleStack = styled(Stack)`
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
`;

export const StyledTextStack = styled(Stack)`
  flex-direction: column;
  gap: 24px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  ul {
    margin: 0;
  }
`;
