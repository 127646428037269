import { Typography } from '@mui/material';
import { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { t } from 'i18next';

import { TxKeyPath } from 'locales/i18n';

import { StyledButton } from './styles';

export type ButtonPresets = 'primary' | 'secondary' | 'start' | 'approve' | 'reject' | 'cancel';

interface IButtonProps extends LoadingButtonProps {
  text?: string;
  tx?: TxKeyPath;
  preset?: ButtonPresets;
}

export function Button({ text, tx, preset = 'primary', ...rest }: IButtonProps): JSX.Element {
  const i18nText = tx && t(tx);
  const content = i18nText || text;

  return (
    <StyledButton preset={preset} fullWidth {...rest}>
      <Typography variant="button" fontWeight="normal">
        {content}
      </Typography>
    </StyledButton>
  );
}
