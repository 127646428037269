import { Link, Stack, styled } from '@mui/material';

export const StyledLinkStack = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.common.gold};
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledCheckboxStack = styled(Stack)`
  height: 28px;

  &:not(:last-child) {
    margin-bottom: 26px;
  }
`;
