enum ApplicationStatuses {
  NEW = 'new',
  PENDING = 'pending',
  USER_ACTION_PENDING = 'user-action-pending',
  PAID = 'paid',
  EDIT_REQUEST = 'edit-request',
  COMPLETED = 'completed',
  APPROVED = 'approved', // landlord approves the application
  CANCELED = 'canceled', // tenant rejects the application
  REJECTED = 'rejected' // landlord rejects the application
}

export { ApplicationStatuses };
