import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type BulkResModalState = {
  isBulkReqModalOpen: boolean;
  isBulkResModalOpen: boolean;
};

const initialState: BulkResModalState = {
  isBulkReqModalOpen: false,
  isBulkResModalOpen: false
};

export const bulkResModalSlice = createSlice({
  name: 'bulkResModal',
  initialState,
  reducers: {
    setIsBulkReqModalOpen(state: BulkResModalState, { payload }: PayloadAction<boolean>) {
      state.isBulkReqModalOpen = payload;
    },
    setIsBulkResModalOpen(state: BulkResModalState, { payload }: PayloadAction<boolean>) {
      state.isBulkResModalOpen = payload;
    }
  }
});

export const { setIsBulkReqModalOpen, setIsBulkResModalOpen } = bulkResModalSlice.actions;

export default bulkResModalSlice.reducer;
