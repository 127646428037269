import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="101"
    height="100"
    viewBox="0 0 101 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.5 91.6663C73.5118 91.6663 92.1666 73.0115 92.1666 49.9997C92.1666 26.9878 73.5118 8.33301 50.5 8.33301C27.4881 8.33301 8.83331 26.9878 8.83331 49.9997C8.83331 73.0115 27.4881 91.6663 50.5 91.6663ZM50.5 66.6663C59.7047 66.6663 67.1666 59.2044 67.1666 49.9997C67.1666 40.7949 59.7047 33.333 50.5 33.333C41.2952 33.333 33.8333 40.7949 33.8333 49.9997C33.8333 59.2044 41.2952 66.6663 50.5 66.6663Z"
      fill="url(#paint0_linear_108_3500)"
    />
    <path
      d="M23.3299 81.5906L41.133 63.7875C39.3968 62.6057 37.8954 61.1044 36.7134 59.3683L18.9104 77.1713C20.2714 78.7521 21.749 80.2297 23.3299 81.5906Z"
      fill="url(#paint1_linear_108_3500)"
    />
    <path
      d="M18.91 22.8296L36.713 40.6326C37.8949 38.8964 39.3963 37.3951 41.1324 36.2132L23.3294 18.4102C21.7485 19.7711 20.2709 21.2487 18.91 22.8296Z"
      fill="url(#paint2_linear_108_3500)"
    />
    <path
      d="M59.8681 36.2136L77.6711 18.4106C79.252 19.7716 80.7295 21.2492 82.0905 22.8301L64.2874 40.6332C63.1055 38.8969 61.6042 37.3956 59.8681 36.2136Z"
      fill="url(#paint3_linear_108_3500)"
    />
    <path
      d="M82.09 77.1708L64.287 59.3677C63.105 61.1039 61.6037 62.6052 59.8676 63.7872L77.6706 81.5902C79.2515 80.2292 80.7291 78.7516 82.09 77.1708Z"
      fill="url(#paint4_linear_108_3500)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_108_3500"
        x1="50.5"
        y1="8.33301"
        x2="50.5"
        y2="91.6663"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_108_3500"
        x1="50.5002"
        y1="18.4102"
        x2="50.5002"
        y2="81.5906"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_108_3500"
        x1="50.5002"
        y1="18.4102"
        x2="50.5002"
        y2="81.5906"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_108_3500"
        x1="50.5002"
        y1="18.4102"
        x2="50.5002"
        y2="81.5906"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_108_3500"
        x1="50.5002"
        y1="18.4102"
        x2="50.5002"
        y2="81.5906"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
    </defs>
  </svg>
);

export default React.memo(Icon);
