import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 0.5C5.376 0.5 0 5.876 0 12.5C0 19.124 5.376 24.5 12 24.5C18.624 24.5 24 19.124 24 12.5C24 5.876 18.636 0.5 12 0.5ZM12.036 17.576C9.228 17.576 6.96 15.308 6.96 12.5C6.96 9.692 9.228 7.424 12.036 7.424C14.844 7.424 17.112 9.692 17.112 12.5C17.112 15.308 14.844 17.576 12.036 17.576Z"
      fill="url(#paint0_linear_926_829)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_926_829"
        x1="12"
        y1="0.5"
        x2="12"
        y2="24.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#062236" />
        <stop offset="1" stopColor="#212528" />
      </linearGradient>
    </defs>
  </svg>
);

export default React.memo(Icon);
