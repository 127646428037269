import { lazy } from 'react';

import { SimpleLayout } from 'layout';
import { Loadable } from 'components';
import { AppRoutes } from 'enums';

import { PublicRoute } from './public-route';

const ForgotPasswordPage = Loadable(lazy(() => import('pages/forgot-password')));
const NewPasswordPage = Loadable(lazy(() => import('pages/new-password')));
const VerifyEmailPage = Loadable(lazy(() => import('pages/verify-email')));
const SelectRolePage = Loadable(lazy(() => import('pages/select-role')));
const ProfilePage = Loadable(lazy(() => import('pages/profile')));
const SignUpPage = Loadable(lazy(() => import('pages/sign-up')));
const LoginPage = Loadable(lazy(() => import('pages/login')));

export const LoginRoutes = {
  path: AppRoutes.SELECT_ROLE_PAGE,
  element: (
    <PublicRoute>
      <SimpleLayout />
    </PublicRoute>
  ),
  children: [
    {
      index: true,
      element: <SelectRolePage />
    },
    {
      path: AppRoutes.SIGN_UP_PAGE,
      element: <SignUpPage />
    },
    {
      path: AppRoutes.VERIFY_EMAIL_PAGE,
      element: <VerifyEmailPage />
    },
    {
      path: AppRoutes.LOG_IN_PAGE,
      element: <LoginPage />
    },
    {
      path: AppRoutes.FORGOT_PASSWORD_PAGE,
      element: <ForgotPasswordPage />
    },
    {
      path: AppRoutes.NEW_PASSWORD_PAGE,
      element: <NewPasswordPage />
    },
    {
      path: AppRoutes.PROFILE_PAGE,
      element: <ProfilePage />
    }
  ]
};
