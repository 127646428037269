import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    height="200px"
    width="200px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    fill="#000000"
    {...props}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <polygon fill="#DEC093" points="111.492,349.387 0,512 512,512 512,163.166 "></polygon>
      <g>
        <polygon fill="#C5AB83" points="192.006,337.582 0,512 0,163.166 192.006,236.859 "></polygon>
        <polygon fill="#C5AB83" points="478.242,193.832 478.242,481.336 320.592,336.987 "></polygon>
      </g>
      <polygon
        fill="#737373"
        points="0,163.166 104.085,257.719 256,339.462 407.913,257.722 512,163.166 407.912,163.166 104.088,163.166 "
      ></polygon>
      <polygon
        fill="#DCDCDC"
        points="104.088,212.103 104.088,257.721 256,395.725 407.912,257.722 407.912,212.103 "
      ></polygon>
      <polygon
        fill="#EEEEEE"
        points="407.912,0 407.912,212.103 256,350.118 104.088,212.103 104.088,0 "
      ></polygon>
      <g>
        <path
          fill="#4D4D4D"
          d="M340.396,75.956H171.604c-4.661,0-8.44-3.779-8.44-8.44s3.779-8.44,8.44-8.44h168.791 c4.661,0,8.44,3.779,8.44,8.44S345.057,75.956,340.396,75.956z"
        ></path>
        <path
          fill="#4D4D4D"
          d="M340.396,120.967H171.604c-4.661,0-8.44-3.779-8.44-8.44c0-4.661,3.779-8.44,8.44-8.44h168.791 c4.661,0,8.44,3.779,8.44,8.44C348.835,117.188,345.057,120.967,340.396,120.967z"
        ></path>
        <path
          fill="#4D4D4D"
          d="M256,165.978h-84.396c-4.661,0-8.44-3.779-8.44-8.44s3.779-8.44,8.44-8.44H256 c4.661,0,8.44,3.779,8.44,8.44S260.661,165.978,256,165.978z"
        ></path>
      </g>
    </g>
  </svg>
);

export default React.memo(Icon);
