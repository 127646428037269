import { Typography } from '@mui/material';
import { t } from 'i18next';

import {
  ADDRESS,
  ADDRESS_LINK,
  EMAIL,
  EMAIL_LINK,
  PHONE,
  PHONE_LINK,
  WEB_SITE,
  WEB_SITE_LINK
} from 'constants/public-data';

import {
  StyledDialog,
  StyledDialogContent,
  StyledLink,
  StyledList,
  StyledListItem,
  StyledScrollStack,
  StyledTextStack,
  StyledTitleStack,
  TextSubtitleTypography,
  TextTitleTypography,
  TextTypography
} from './styles';

type Props = {
  open: boolean;
  handleCancel: () => void;
};

export function PrivacyPolicyModal({ open, handleCancel }: Props): JSX.Element {
  return (
    <StyledDialog open={open} onClose={handleCancel}>
      <StyledDialogContent>
        <StyledTitleStack>
          <Typography variant="h3">{t('privacyPolicy.title')}</Typography>
        </StyledTitleStack>

        <StyledScrollStack>
          <StyledTextStack>
            <TextTitleTypography variant="subtitle1">
              {t('privacyPolicy.block1title')}
            </TextTitleTypography>

            <TextTypography>
              {t('privacyPolicy.block1text1')}

              <StyledLink href={WEB_SITE_LINK} target="_blank">
                {WEB_SITE}
              </StyledLink>

              {t('privacyPolicy.block1text2')}
            </TextTypography>
          </StyledTextStack>

          <StyledTextStack>
            <TextTitleTypography variant="subtitle1">
              {t('privacyPolicy.block2title')}
            </TextTitleTypography>

            <TextTypography>{t('privacyPolicy.block2text')}</TextTypography>
          </StyledTextStack>

          <StyledTextStack>
            <TextTitleTypography variant="subtitle1">
              {t('privacyPolicy.block3title')}
            </TextTitleTypography>

            <TextSubtitleTypography variant="checkTextValue">
              {t('privacyPolicy.block3subtitle1')}
            </TextSubtitleTypography>

            <TextTypography>{t('privacyPolicy.block3text1')}</TextTypography>

            <StyledList>
              <StyledListItem>
                <TextTypography>{t('privacyPolicy.block3list1Item1')}</TextTypography>
              </StyledListItem>

              <StyledListItem>
                <TextTypography>{t('privacyPolicy.block3list1Item2')}</TextTypography>
              </StyledListItem>

              <StyledListItem>
                <TextTypography>{t('privacyPolicy.block3list1Item3')}</TextTypography>
              </StyledListItem>
            </StyledList>

            <TextTypography>{t('privacyPolicy.block3text2')}</TextTypography>

            <StyledList>
              <StyledListItem>
                <TextTypography>{t('privacyPolicy.block3list2Item1')}</TextTypography>
              </StyledListItem>

              <StyledListItem>
                <TextTypography>{t('privacyPolicy.block3list2Item2')}</TextTypography>
              </StyledListItem>
            </StyledList>

            <TextSubtitleTypography variant="checkTextValue">
              {t('privacyPolicy.block3subtitle2')}
            </TextSubtitleTypography>

            <TextTypography>{t('privacyPolicy.block3text3')}</TextTypography>

            <StyledList>
              <StyledListItem>
                <TextTypography>{t('privacyPolicy.block3list3Item1')}</TextTypography>
              </StyledListItem>

              <StyledListItem>
                <TextTypography>{t('privacyPolicy.block3list3Item2')}</TextTypography>
              </StyledListItem>
            </StyledList>
          </StyledTextStack>

          <StyledTextStack>
            <TextTitleTypography variant="subtitle1">
              {t('privacyPolicy.block4title')}
            </TextTitleTypography>

            <TextTypography>{t('privacyPolicy.block4text')}</TextTypography>

            <StyledList>
              <StyledListItem>
                <TextTypography>{t('privacyPolicy.block4listItem1')}</TextTypography>
              </StyledListItem>

              <StyledListItem>
                <TextTypography>{t('privacyPolicy.block4listItem2')}</TextTypography>
              </StyledListItem>

              <StyledListItem>
                <TextTypography>{t('privacyPolicy.block4listItem3')}</TextTypography>
              </StyledListItem>

              <StyledListItem>
                <TextTypography>{t('privacyPolicy.block4listItem4')}</TextTypography>
              </StyledListItem>
            </StyledList>
          </StyledTextStack>

          <StyledTextStack>
            <TextTitleTypography variant="subtitle1">
              {t('privacyPolicy.block5title')}
            </TextTitleTypography>

            <TextTypography>{t('privacyPolicy.block5text')}</TextTypography>

            <StyledList>
              <StyledListItem>
                <TextTypography>{t('privacyPolicy.block5listItem1')}</TextTypography>
              </StyledListItem>

              <StyledListItem>
                <TextTypography>{t('privacyPolicy.block5listItem2')}</TextTypography>
              </StyledListItem>

              <StyledListItem>
                <TextTypography>{t('privacyPolicy.block5listItem3')}</TextTypography>
              </StyledListItem>
            </StyledList>
          </StyledTextStack>

          <StyledTextStack>
            <TextTitleTypography variant="subtitle1">
              {t('privacyPolicy.block6title')}
            </TextTitleTypography>

            <TextTypography>{t('privacyPolicy.block6text')}</TextTypography>
          </StyledTextStack>

          <StyledTextStack>
            <TextTitleTypography variant="subtitle1">
              {t('privacyPolicy.block7title')}
            </TextTitleTypography>

            <TextTypography>{t('privacyPolicy.block7text')}</TextTypography>
          </StyledTextStack>

          <StyledTextStack>
            <TextTitleTypography variant="subtitle1">
              {t('privacyPolicy.block8title')}
            </TextTitleTypography>

            <TextTypography>{t('privacyPolicy.block8text')}</TextTypography>
          </StyledTextStack>

          <StyledTextStack>
            <TextTitleTypography variant="subtitle1">
              {t('privacyPolicy.block9title')}
            </TextTitleTypography>

            <TextTypography>{t('privacyPolicy.block9text1')}</TextTypography>

            <StyledList>
              <StyledListItem>
                <TextTypography>
                  {t('privacyPolicy.block9listItem1')}

                  <StyledLink href={EMAIL_LINK}>{EMAIL}</StyledLink>
                </TextTypography>
              </StyledListItem>

              <StyledListItem>
                <TextTypography>
                  {t('privacyPolicy.block9listItem2')}

                  <StyledLink href={PHONE_LINK}>{PHONE}</StyledLink>
                </TextTypography>
              </StyledListItem>

              <StyledListItem>
                <TextTypography>
                  {t('privacyPolicy.block9listItem3')}

                  <StyledLink href={ADDRESS_LINK} target="_blank">
                    {ADDRESS}
                  </StyledLink>
                </TextTypography>
              </StyledListItem>
            </StyledList>

            <TextTypography>{t('privacyPolicy.block9text2')}</TextTypography>
          </StyledTextStack>
        </StyledScrollStack>
      </StyledDialogContent>
    </StyledDialog>
  );
}
