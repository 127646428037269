import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.78359 1.66699C6.13014 1.66699 6.41107 1.93717 6.41107 2.27044V3.48772C6.96766 3.47734 7.59154 3.47734 8.29352 3.47734H11.6401C12.3421 3.47734 12.9659 3.47734 13.5225 3.48772V2.27044C13.5225 1.93717 13.8035 1.66699 14.15 1.66699C14.4966 1.66699 14.7775 1.93717 14.7775 2.27044V3.54111C15.9817 3.63384 16.7722 3.86143 17.353 4.41998C17.9338 4.97853 18.1705 5.7388 18.2669 6.89688L18.3334 7.50033H2.43715H1.66669V6.89688C1.76312 5.7388 1.99977 4.97853 2.58056 4.41998C3.16136 3.86143 3.9519 3.63384 5.15611 3.54111V2.27044C5.15611 1.93717 5.43704 1.66699 5.78359 1.66699Z"
      fill="url(#paint0_linear_1936_2580)"
    />
    <path
      opacity="0.5"
      d="M18.3333 11.6667V10.0001C18.3333 9.30087 18.3306 8.05439 18.3199 7.5H1.67471C1.66396 8.05439 1.66668 9.30087 1.66668 10.0001V11.6667C1.66668 14.8094 1.66668 16.3808 2.64299 17.3571C3.6193 18.3334 5.19065 18.3334 8.33335 18.3334H11.6667C14.8094 18.3334 16.3807 18.3334 17.357 17.3571C18.3333 16.3808 18.3333 14.8094 18.3333 11.6667Z"
      fill="url(#paint1_linear_1936_2580)"
    />
    <path
      d="M15 14.1667C15 14.6269 14.6269 15 14.1666 15C13.7064 15 13.3333 14.6269 13.3333 14.1667C13.3333 13.7064 13.7064 13.3333 14.1666 13.3333C14.6269 13.3333 15 13.7064 15 14.1667Z"
      fill="url(#paint2_linear_1936_2580)"
    />
    <path
      d="M15 10.8333C15 11.2936 14.6269 11.6667 14.1666 11.6667C13.7064 11.6667 13.3333 11.2936 13.3333 10.8333C13.3333 10.3731 13.7064 10 14.1666 10C14.6269 10 15 10.3731 15 10.8333Z"
      fill="url(#paint3_linear_1936_2580)"
    />
    <path
      d="M10.8334 14.1667C10.8334 14.6269 10.4603 15 10 15C9.53978 15 9.16669 14.6269 9.16669 14.1667C9.16669 13.7064 9.53978 13.3333 10 13.3333C10.4603 13.3333 10.8334 13.7064 10.8334 14.1667Z"
      fill="url(#paint4_linear_1936_2580)"
    />
    <path
      d="M10.8334 10.8333C10.8334 11.2936 10.4603 11.6667 10 11.6667C9.53978 11.6667 9.16669 11.2936 9.16669 10.8333C9.16669 10.3731 9.53978 10 10 10C10.4603 10 10.8334 10.3731 10.8334 10.8333Z"
      fill="url(#paint5_linear_1936_2580)"
    />
    <path
      d="M6.66667 14.1667C6.66667 14.6269 6.29357 15 5.83333 15C5.3731 15 5 14.6269 5 14.1667C5 13.7064 5.3731 13.3333 5.83333 13.3333C6.29357 13.3333 6.66667 13.7064 6.66667 14.1667Z"
      fill="url(#paint6_linear_1936_2580)"
    />
    <path
      d="M6.66667 10.8333C6.66667 11.2936 6.29357 11.6667 5.83333 11.6667C5.3731 11.6667 5 11.2936 5 10.8333C5 10.3731 5.3731 10 5.83333 10C6.29357 10 6.66667 10.3731 6.66667 10.8333Z"
      fill="url(#paint7_linear_1936_2580)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1936_2580"
        x1="10"
        y1="1.66699"
        x2="10"
        y2="7.50033"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1936_2580"
        x1="9.9998"
        y1="7.5"
        x2="9.9998"
        y2="18.3334"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1936_2580"
        x1="14.1666"
        y1="10"
        x2="14.1666"
        y2="15"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1936_2580"
        x1="14.1666"
        y1="10"
        x2="14.1666"
        y2="15"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1936_2580"
        x1="10"
        y1="10"
        x2="10"
        y2="15"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1936_2580"
        x1="10"
        y1="10"
        x2="10"
        y2="15"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1936_2580"
        x1="5.83333"
        y1="10"
        x2="5.83333"
        y2="15"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1936_2580"
        x1="5.83333"
        y1="10"
        x2="5.83333"
        y2="15"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
    </defs>
  </svg>
);

export default React.memo(Icon);
