import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { useFormContext, Controller } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

import { useState, useTheme } from 'hooks';
import { KeyboardsKeys } from 'enums';
import { formatSSN } from 'helpers';

type Props = OutlinedInputProps & {
  name: string;
  customType?: 'number';
};

export function RHFOutlinedInput({ name, type, customType, inputProps, ...other }: Props) {
  const [inputValue, setInputValue] = useState<string>('');

  const { control } = useFormContext();
  const { palette } = useTheme();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const isNotDeleteKey =
      event.key !== KeyboardsKeys.DELETE &&
      event.key !== KeyboardsKeys.BACKSPACE &&
      event.key !== KeyboardsKeys.NUMPAD_DECIMAL;

    if (type === 'number' && !/\d/.test(event.key) && isNotDeleteKey) {
      event.preventDefault();
    }
    if (type === 'ssn' && inputValue.length === 11 && isNotDeleteKey) {
      event.preventDefault();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        let valueToShow = field.value;

        if (customType && customType === 'number') {
          const numberValue = Number(field.value);

          valueToShow = field.value !== '' && !isNaN(numberValue) ? numberValue : '';
        }

        if (type === 'ssn') {
          valueToShow = formatSSN(field.value);
        }

        return (
          <>
            <InputLabel htmlFor={name}>{other.label}</InputLabel>
            <OutlinedInput
              {...field}
              fullWidth
              id={name}
              color="primary"
              type={type === 'ssn' ? 'text' : type}
              value={valueToShow}
              onChange={event => {
                const newValue = event.target.value;

                setInputValue(newValue);
                if (type === 'number') {
                  field.onChange(Number(event.target.value));
                } else {
                  field.onChange(event.target.value);
                }
              }}
              sx={{
                '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  borderColor: palette.common.disabled,
                  color: palette.common.black
                },
                '&.MuiOutlinedInput-root input': {
                  padding: '10px 16px',
                  color: palette.common.black
                },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: palette.common.disabled
                },
                '&.MuiOutlinedInput-root  input:-webkit-autofill': {
                  borderRadius: '10px',
                  border: `1px solid ${palette.common.disabled}`,
                  backgroundColor: `${palette.common.white} !important`,
                  boxShadow: `0 0 0 50px transparent inset !important`,
                  WebkitTextFillColor: `${palette.common.black} !important`
                }
              }}
              inputProps={{ 'data-testid': name, ...inputProps }}
              onKeyDown={handleKeyPress}
              error={!!error}
              {...other}
            />
            {error && (
              <FormHelperText sx={{ color: palette.common.error, fontSize: '12px' }}>
                {error.message}
              </FormHelperText>
            )}
          </>
        );
      }}
    />
  );
}
