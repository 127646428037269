import { PersistGate } from 'redux-persist/integration/react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import { LocalizationProvider } from 'locales';
import { persistor, store } from 'store';
import ThemeCustomization from 'themes';
import { Loader } from 'components';
import router from 'routes';
import './themes/globals.css';

const SNACKBAR_DURATION: number = 3000;

export default function App(): JSX.Element {
  return (
    <ThemeCustomization>
      <LocalizationProvider>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          autoHideDuration={SNACKBAR_DURATION}
        >
          <Provider store={store}>
            <PersistGate loading={<Loader />} persistor={persistor}>
              <RouterProvider router={router} />
            </PersistGate>
          </Provider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeCustomization>
  );
}
