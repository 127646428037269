import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ApplicationStatuses } from 'enums';

export type DashboardState = {
  searchParam: string;
  selectedStatus?: ApplicationStatuses;
  pageSize: number;
  page: number;
};

const PAGE_SIZE: number = 15;
const PAGE: number = 1;

const initialState: DashboardState = {
  searchParam: '',
  selectedStatus: undefined,
  pageSize: PAGE_SIZE,
  page: PAGE
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateDashboardData: (state: DashboardState, action: PayloadAction<DashboardState>) => ({
      ...state,
      ...action.payload
    }),
    clearDashboardData: () => ({ ...initialState }),
    updateSearchParam(state: DashboardState, { payload }: PayloadAction<string>) {
      state.searchParam = payload;
    },
    updateSelectedStatus(state: DashboardState, { payload }: PayloadAction<ApplicationStatuses>) {
      state.selectedStatus = payload;
    },
    clearSelectedStatus(state: DashboardState) {
      state.selectedStatus = initialState.selectedStatus;
    },
    updatePage(state: DashboardState, { payload }: PayloadAction<number>) {
      state.page = payload;
    },
    clearPage(state: DashboardState) {
      state.page = initialState.page;
    },
    updatePageSize(state: DashboardState, { payload }: PayloadAction<number>) {
      state.pageSize = payload;
    }
  }
});

export const {
  updateDashboardData,
  updateSearchParam,
  updateSelectedStatus,
  clearSelectedStatus,
  updatePage,
  updatePageSize,
  clearPage,
  clearDashboardData
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
