import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Loader } from 'components';

import { Header } from './components';
import { StyledBox } from './styles';

export function MainLayout(): JSX.Element {
  return (
    <Suspense fallback={<Loader />}>
      <StyledBox>
        <Outlet />
        <Header />
      </StyledBox>
    </Suspense>
  );
}
