import { Button, Stack, styled } from '@mui/material';

export const StyledStack = styled(Stack)`
  flex-direction: row;
  align-items: center;
  height: 48px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.disabled};
`;

export const StyledButton = styled(Button)<{
  selected: boolean;
}>`
  height: 48px;
  border-radius: 0;

  padding-left: 16px;
  padding-right: 16px;

  &:first-child {
    padding-left: 0;
  }

  color: ${({ selected, theme }) =>
    selected ? theme.palette.common.black : theme.palette.common.disabled};

  ${({ selected, theme }) =>
    selected &&
    `
    border-bottom: 2px solid ${theme.palette.primary.main};
  `}

  &:hover,
  &:focus {
    opacity: 0.8;
    border-bottom: 2px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

export const Badge = styled('div')<{ selected: boolean }>`
  padding: 2px 10px;
  border-radius: 20px;
  margin-left: 4px;
  background-color: ${({ selected, theme }) => (selected ? theme.palette.primary.main : '#E1E4EA')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ selected, theme }) => (selected ? theme.palette.common.white : '#99A0AE')};
`;
