export default function Dialog() {
  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          background: 'transparent',
          '& .paper': {
            background: 'transparent'
          }
        }
      }
    }
  };
}
