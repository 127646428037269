import { t } from 'i18next';

import { PropertyStatuses, ApplicationStatuses } from 'enums';

import { StyledBox } from './styles';

type Props = {
  status: ApplicationStatuses | PropertyStatuses;
  onClick?: () => void;
};

export function StatusBox({ status, onClick }: Props): JSX.Element {
  return (
    <StyledBox
      status={status}
      onClick={onClick}
      sx={{
        fontWeight: 'normal'
      }}
    >
      {t(`statusBox.type${status}`)}
    </StyledBox>
  );
}
