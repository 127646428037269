enum PropertyFields {
  PROPERTY_NAME = 'propertyName',
  DEPOSIT = 'deposit',
  RENT = 'rent',
  LEASE_TERM = 'leaseTerm',
  STATUS = 'status',
  COUNTRY = 'country',
  STATE = 'state',
  CITY = 'city',
  ADDRESS_LINE_1 = 'addressLine1',
  ADDRESS_LINE_2 = 'addressLine2',
  POSTAL_CODE = 'postalCode'
}

export { PropertyFields };
