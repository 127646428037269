import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.69198 1.09327C0.916619 0.831188 1.31118 0.800837 1.57326 1.02548L6.99985 5.67684L12.4264 1.02548C12.6885 0.800838 13.0831 0.831189 13.3077 1.09327C13.5324 1.35535 13.502 1.74991 13.2399 1.97455L7.40659 6.97455C7.17254 7.17517 6.82716 7.17517 6.5931 6.97455L0.759771 1.97455C0.497692 1.74991 0.467341 1.35535 0.69198 1.09327Z"
      fill={props.color}
    />
  </svg>
);

export default React.memo(Icon);
