enum TestIds {
  ADD_PROPERTY_FORM = 'addPropertyForm',
  EDIT_CHECKS_FORM = 'editChecksForm',
  ACCORDION_SUMMARY = 'accordionSummary',
  SIGN_UP_FORM = 'signUpForm'
}

const { ADD_PROPERTY_FORM, EDIT_CHECKS_FORM, ACCORDION_SUMMARY, SIGN_UP_FORM } = TestIds;

export { TestIds, ADD_PROPERTY_FORM, EDIT_CHECKS_FORM, ACCORDION_SUMMARY, SIGN_UP_FORM };
