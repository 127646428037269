import { Box, Typography } from '@mui/material';
import { t } from 'i18next';

import noDataImg from './no-data.png';
import { StyledStack } from './styles';

export function NoDataFound(): JSX.Element {
  return (
    <StyledStack>
      <Box component="img" src={noDataImg} width={108} height={108} />

      <Typography variant="caption">{t('noDataFound.title')}</Typography>
    </StyledStack>
  );
}
