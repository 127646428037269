import { Navigate } from 'react-router-dom';

import { useAppSelector } from 'hooks';
import { AppRoutes, Roles, VerifyEmailReasons } from 'enums';

type Props = {
  children: JSX.Element;
};

export function PrivateRoute({ children }: Props): JSX.Element {
  const { accessToken, isEmailVerified, role, city } = useAppSelector(state => state.auth);

  if (role === Roles.TENANT || role === Roles.LANDLORD) {
    switch (true) {
      case accessToken && isEmailVerified && !!city:
        return <>{children}</>;

      case accessToken && isEmailVerified:
        return <Navigate to={AppRoutes.PROFILE_PAGE} replace={true} />;

      case accessToken && !!city:
        return (
          <Navigate
            to={`${AppRoutes.VERIFY_EMAIL_PAGE}?reason=${VerifyEmailReasons.SIGN_UP}`}
            replace={true}
          />
        );

      case !!accessToken:
        return (
          <Navigate
            to={`${AppRoutes.VERIFY_EMAIL_PAGE}?reason=${VerifyEmailReasons.SIGN_UP}`}
            replace={true}
          />
        );

      default:
        return <Navigate to={AppRoutes.LOG_IN_PAGE} replace={true} />;
    }
  } else {
    switch (true) {
      case accessToken && isEmailVerified:
        return <>{children}</>;

      case !!accessToken:
        return (
          <Navigate
            to={`${AppRoutes.VERIFY_EMAIL_PAGE}?reason=${VerifyEmailReasons.SIGN_UP}`}
            replace={true}
          />
        );

      default:
        return <Navigate to={AppRoutes.LOG_IN_PAGE} replace={true} />;
    }
  }
}
