import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQueryWithReauth } from 'store/api-base-query';
import { ApiRoutes } from 'enums';
import { SendTokenBankAccessIncomeReq, SendTokenPayrollIncomeReq } from 'types';

export const plaidApi = createApi({
  reducerPath: 'PlaidApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    getTokenIdentityVerification: builder.query<{ identityVerificationLinkToken: string }, void>({
      query: () => ({
        url: ApiRoutes.PLAID_IDENTITY_GET_TOKEN,
        method: 'GET'
      })
    }),
    sendTokenIdentityVerification: builder.mutation<
      string,
      { identityVerificationSessionId: string }
    >({
      query: body => ({
        url: ApiRoutes.PLAID_IDENTITY_SEND_TOKEN,
        method: 'POST',
        body
      })
    }),
    getTokenBankIncome: builder.mutation<
      { bankIncomeAndAssetsLinkToken: string },
      { applicationId: string }
    >({
      query: body => ({
        url: ApiRoutes.PLAID_BANK_INCOME_GET_TOKEN,
        method: 'POST',
        body
      })
    }),
    sendTokenBankIncome: builder.mutation<void, SendTokenBankAccessIncomeReq>({
      query: body => ({
        url: ApiRoutes.PLAID_BANK_INCOME_SEND_TOKEN,
        method: 'POST',
        body
      })
    }),
    getTokenPayrollIncome: builder.mutation<
      { payrollIncomeLinkToken: string },
      { applicationId: string }
    >({
      query: body => ({
        url: ApiRoutes.PLAID_PAYROLL_INCOME_GET_TOKEN,
        method: 'POST',
        body
      })
    }),
    sendTokenPayrollIncome: builder.mutation<string, SendTokenPayrollIncomeReq>({
      query: body => ({
        url: ApiRoutes.PLAID_PAYROLL_INCOME_SEND_TOKEN,
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useGetTokenIdentityVerificationQuery,
  useSendTokenIdentityVerificationMutation,
  useGetTokenBankIncomeMutation,
  useSendTokenBankIncomeMutation,
  useGetTokenPayrollIncomeMutation,
  useSendTokenPayrollIncomeMutation
} = plaidApi;
