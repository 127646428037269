import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.80541 1.94006C5.01372 1.94006 1.93994 5.01384 1.93994 8.80553C1.93994 12.5972 5.01372 15.671 8.80541 15.671C12.5971 15.671 15.6709 12.5972 15.6709 8.80553C15.6709 5.01384 12.5971 1.94006 8.80541 1.94006ZM0.689941 8.80553C0.689941 4.32348 4.32336 0.690063 8.80541 0.690063C13.2875 0.690063 16.9209 4.32348 16.9209 8.80553C16.9209 13.2876 13.2875 16.921 8.80541 16.921C4.32336 16.921 0.689941 13.2876 0.689941 8.80553Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5727 13.9629C13.8164 13.7185 14.2121 13.718 14.4565 13.9618L17.3932 16.8909C17.6376 17.1346 17.6381 17.5303 17.3944 17.7747C17.1506 18.0191 16.7549 18.0196 16.5105 17.7759L13.5738 14.8468C13.3294 14.6031 13.3289 14.2073 13.5727 13.9629Z"
      fill={props.color}
    />
  </svg>
);

export default React.memo(Icon);
