import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6668 12L10.6668 4M10.6668 4L13.3335 6.75M10.6668 4L8.00016 6.75"
      stroke="#B5B5B5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.33333 4L5.33333 12M5.33333 12L8 9.25M5.33333 12L2.66667 9.25"
      stroke="#B5B5B5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default React.memo(Icon);
