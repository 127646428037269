import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="101"
    height="100"
    viewBox="0 0 101 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.5"
      d="M30.6876 8.33334H70.3124C75.1411 8.33334 77.5554 8.33334 79.5025 9.01087C83.1949 10.2957 86.0938 13.28 87.3419 17.0811C88 19.0856 88 21.5711 88 26.5419V84.8925C88 88.4685 83.8958 90.3658 81.2996 87.99C79.7743 86.5943 77.4757 86.5943 75.9504 87.99L73.9375 89.832C71.2642 92.2782 67.2358 92.2782 64.5625 89.832C61.8892 87.3857 57.8608 87.3857 55.1875 89.832C52.5142 92.2782 48.4858 92.2782 45.8125 89.832C43.1392 87.3857 39.1108 87.3857 36.4375 89.832C33.7642 92.2782 29.7358 92.2782 27.0625 89.832L25.0496 87.99C23.5243 86.5943 21.2257 86.5943 19.7004 87.99C17.1042 90.3658 13 88.4685 13 84.8925V26.5419C13 21.5711 13 19.0856 13.6581 17.0811C14.9062 13.28 17.8051 10.2957 21.4975 9.01087C23.4446 8.33334 25.8589 8.33334 30.6876 8.33334Z"
      fill="url(#paint0_linear_108_2501)"
    />
    <path
      d="M63.2477 35.4147C64.3972 34.1273 64.2854 32.1518 62.998 31.0023C61.7106 29.8528 59.7351 29.9647 58.5856 31.2521L46.0357 45.308L42.4144 41.2521C41.2649 39.9647 39.2894 39.8528 38.002 41.0023C36.7146 42.1518 36.6028 44.1273 37.7523 45.4147L43.7047 52.0813C44.2976 52.7454 45.1455 53.125 46.0357 53.125C46.9259 53.125 47.7739 52.7454 48.3668 52.0813L63.2477 35.4147Z"
      fill="url(#paint1_linear_108_2501)"
    />
    <path
      d="M31.75 61.4584C30.0241 61.4584 28.625 62.8575 28.625 64.5834C28.625 66.3093 30.0241 67.7084 31.75 67.7084H69.25C70.9759 67.7084 72.375 66.3093 72.375 64.5834C72.375 62.8575 70.9759 61.4584 69.25 61.4584H31.75Z"
      fill="url(#paint2_linear_108_2501)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_108_2501"
        x1="50.5"
        y1="8.33334"
        x2="50.5"
        y2="91.6667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_108_2501"
        x1="50.5"
        y1="30.2083"
        x2="50.5"
        y2="67.7084"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_108_2501"
        x1="50.5"
        y1="30.2083"
        x2="50.5"
        y2="67.7084"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
    </defs>
  </svg>
);

export default React.memo(Icon);
