import { useEffect, useState } from 'react';
import { SxProps } from '@mui/system';

import { useGetFileQuery } from 'store/profile';

import { StyledAvatar } from './styles';

type Props = {
  firstName: string;
  lastName: string;
  avatarUrl?: string;
  sx?: SxProps;
};

export function Avatar({ firstName, lastName, avatarUrl, sx }: Props): JSX.Element {
  const fullName = `${firstName} ${lastName}`;

  const firstNameInitial = firstName.charAt(0).toUpperCase();
  const lastNameInitial = lastName.charAt(0).toUpperCase();
  const initials = `${firstNameInitial}${lastNameInitial}`;

  const [downloadedAvatarURL, setDownloadedAvatarURL] = useState('');
  const { data: avatarFile, isLoading: isAvatarFileLoading } = useGetFileQuery(avatarUrl, {
    skip: !avatarUrl
  });

  useEffect(() => {
    if (!avatarUrl) return;

    let url = '';

    if (!isAvatarFileLoading && avatarFile) {
      url = URL.createObjectURL(avatarFile);
      setDownloadedAvatarURL(url);
    }

    return () => {
      URL.revokeObjectURL(url);
    };
  }, [avatarFile, avatarUrl, isAvatarFileLoading]);

  return (
    <StyledAvatar alt={fullName} src={downloadedAvatarURL} sx={sx}>
      {initials}
    </StyledAvatar>
  );
}
