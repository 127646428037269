import { Box, styled } from '@mui/material';

export const StyledBox = styled(Box)`
  min-height: 100%;
  width: 100%;
  min-width: calc(100vw - 280px);
  padding: 80px;
  padding-top: 38px;
  position: absolute;
  top: 96px;
  color: ${({ theme }) => theme.palette.common.darkKnight};
  background-color: ${({ theme }) => theme.palette.common.white};

  input {
    color: ${({ theme }) => theme.palette.common.black};
  }
`;
