import { lazy } from 'react';

import { SimpleLayout } from 'layout';
import { Loadable } from 'components';

const Error404 = Loadable(lazy(() => import('pages/404')));

export const ErrorRoutes = {
  element: <SimpleLayout />,
  children: [
    {
      path: '*',
      element: <Error404 />
    }
  ]
};
