import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ApiRoutes } from 'enums';
import { baseQueryWithReauth } from 'store/api-base-query';
import {
  SharableEvaluateBody,
  SharableIdentityVerifyBody,
  ShareableEvaluateResponse,
  ShareableIdentityVerifyResponse
} from 'types/api/shareable';

export const shareableApi = createApi({
  reducerPath: 'ShareableApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    sendIdentityVerify: builder.mutation<
      ShareableIdentityVerifyResponse,
      SharableIdentityVerifyBody
    >({
      query: ({ applicationId }) => ({
        url: `${ApiRoutes.SHAREABLE_IDENTITY_VERIFY}/${applicationId}`,
        method: 'POST'
      })
    }),
    sendIdentityEvaluate: builder.mutation<ShareableEvaluateResponse, SharableEvaluateBody>({
      query: ({ applicationId, examId, answers }) => ({
        url: `${ApiRoutes.SHAREABLE_IDENTITY_EVALUATE}/${applicationId}`,
        method: 'POST',
        body: {
          examId,
          answers
        }
      })
    })
  })
});

export const { useSendIdentityVerifyMutation, useSendIdentityEvaluateMutation } = shareableApi;
