import { Colors } from 'enums';

export function getScrollbarCSS() {
  return `
  
    ::-webkit-scrollbar {
      width: 6px;
    }
  
    ::-webkit-scrollbar-track {
      background: ${Colors.DARK};
    }
  
    ::-webkit-scrollbar-thumb {
      height: 35px;
      background: ${Colors.THUNDER_CLOUD}
    }
  
    ::-webkit-scrollbar-thumb:hover {
      background: ${Colors.THUNDER_CLOUD}
    }
    `;
}
