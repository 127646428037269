import { PATTERNS } from 'constants/patterns';

export function phoneNumberFormat(phoneNumber: string): string {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(PATTERNS.PHONE);

  if (match) {
    const intlCode = match[1] ? '+1 ' : '';

    return `${intlCode}(${match[2]}) ${match[3]}-${match[4]}`;
  }

  return phoneNumber;
}
