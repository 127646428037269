import { Box, Typography } from '@mui/material';

import { Button, SVGIcon } from 'components';
import { useEffect, useRef } from 'hooks';
import { TxKeyPath } from 'locales/i18n';
import { t } from 'i18next';

import { IconContainer, StyledDialog, StyledDialogContent, StyledModalStack } from './styles';

type Props = {
  open: boolean;
  titleTx?: TxKeyPath;
  subtitleTx?: TxKeyPath;
  handleDelete: () => Promise<void>;
  handleCancel: () => void;
};

export function DeleteConfirmModal({
  open,
  titleTx,
  subtitleTx,
  handleDelete,
  handleCancel
}: Props): JSX.Element {
  const descriptionElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;

      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <StyledDialog open={open} onClose={handleCancel}>
      <StyledDialogContent>
        <IconContainer>
          <SVGIcon name="exclamationMark" width="11" height="11" />
        </IconContainer>

        <Box display="flex" flexDirection="column" alignItems="center" gap="4px">
          <Typography variant="h3">{titleTx && t(titleTx)}</Typography>
          <Typography variant="caption" fontSize="14px">
            {subtitleTx && t(subtitleTx)}
          </Typography>
        </Box>

        <StyledModalStack>
          <Button tx="common.cancel" preset="secondary" onClick={handleCancel} />
          <Button tx="common.delete" preset="reject" onClick={handleDelete} />
        </StyledModalStack>
      </StyledDialogContent>
    </StyledDialog>
  );
}
