import { Box, Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const StyledBox = styled(Box)`
  height: 80px;
  position: fixed;
  z-index: 10;
  width: 100%;
  padding: 0 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.disabled};
`;

export const StyledStack = styled(Stack)`
  margin-left: 105px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const LinkBar = styled(Stack)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 24px;
`;

export const LinkBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const StyledLink = styled(Link)<{ isActive?: boolean }>`
  color: ${({ theme, isActive }) =>
    isActive ? theme.palette.common.black : theme.palette.common.disabled};
  text-decoration: none;
`;

export const StyledImg = styled('img')`
  display: flex;
  height: 80px;
  width: 80px;
  min-height: 80px;
  margin: 0 auto;
  height: fit-content;
  object-fit: contain;
`;
