import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Roles } from 'enums';
import { RootState } from 'store';

type Auth = {
  accessToken?: string;
  refreshToken?: string;
  isEmailVerified?: boolean;
  isIdentityVerified?: boolean;
  isCongratulationModal?: boolean;
};

type Profile = {
  role?: Roles;
  fullName?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  avatarRelativeUrl?: string;
  email?: string;
  birthDate?: Date | string;
  phone?: string;
  ssn?: string;
  country?: string;
  state?: string;
  city?: string;
  addressLine1?: string;
  addressLine2?: string;
  postalCode?: string;
  isSuspended?: boolean;
};

export type AuthState = Auth & Profile;

const initialState: AuthState = {
  accessToken: '',
  refreshToken: '',
  fullName: '',
  firstName: '',
  middleName: '',
  lastName: '',
  avatarRelativeUrl: '',
  email: '',
  role: undefined,
  isEmailVerified: false,
  isIdentityVerified: false,
  isCongratulationModal: false,
  birthDate: '',
  phone: '',
  ssn: '',
  country: '',
  state: '',
  city: '',
  addressLine1: '',
  addressLine2: '',
  postalCode: '',
  isSuspended: false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthData: (state: AuthState, action: PayloadAction<AuthState>) => ({
      ...state,
      ...action.payload
    }),
    clearAuthData: (state: AuthState) => ({ ...initialState })
  }
});

export const { setAuthData, clearAuthData } = authSlice.actions;
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;

export default authSlice.reducer;
