enum PropertiesOrders {
  CREATED_DATA = 'createdDate',
  ADDRESS = 'address',
  NAME = 'name',
  CITY = 'city',
  STATE = 'state',
  POSTAL_CODE = 'postalCode'
}

export { PropertiesOrders };
