import { useFormContext, Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel, {
  FormControlLabelProps,
  formControlLabelClasses
} from '@mui/material/FormControlLabel';
import { Stack } from '@mui/material';

import { ReactComponent as CheckedIcon } from 'components/sign-in-story/images/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'components/sign-in-story/images/checkbox-unchecked.svg';

interface RHFCheckboxProps extends Omit<FormControlLabelProps, 'control'> {
  children?: JSX.Element;
  name: string;
  helperText?: React.ReactNode;
}

export function RHFCheckbox({ children, name, helperText, ...other }: RHFCheckboxProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <Stack flexDirection="row">
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  data-testid={name}
                  checked={field.value}
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                />
              }
              {...other}
            />
            {children}
          </Stack>

          {(!!error || helperText) && (
            <FormHelperText sx={{ mt: '-5px', fontSize: '12px' }} error={!!error}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}

interface RHFMultiCheckboxProps extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string;
  options: { label: string; value: any }[];
  row?: boolean;
  label?: string;
  spacing?: number;
  helperText?: React.ReactNode;
}

export function RHFMultiCheckbox({
  row,
  name,
  label,
  options,
  spacing,
  helperText,
  sx,
  ...other
}: RHFMultiCheckboxProps) {
  const { control } = useFormContext();

  const getSelected = (selectedItems: string[], item: string) =>
    selectedItems.includes(item)
      ? selectedItems.filter(value => value !== item)
      : [...selectedItems, item];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset">
          {label && (
            <FormLabel component="legend" sx={{ typography: 'body2' }}>
              {label}
            </FormLabel>
          )}

          <FormGroup
            sx={{
              ...(row && {
                flexDirection: 'row'
              }),
              [`& .${formControlLabelClasses.root}`]: {
                '&:not(:last-of-type)': {
                  mb: spacing || 0
                },
                ...(row && {
                  mr: 0,
                  '&:not(:last-of-type)': {
                    mr: spacing || 2
                  }
                })
              },
              ...sx
            }}
          >
            {options.map(option => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={field.value.includes(option.value)}
                    onChange={() => field.onChange(getSelected(field.value, option.value))}
                  />
                }
                label={option.label}
                {...other}
              />
            ))}
          </FormGroup>

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
