import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQueryWithReauth } from 'store/api-base-query';
import { ApiRoutes } from 'enums';
import { QuestionReq } from 'types';

export const supportApi = createApi({
  reducerPath: 'SupportApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    sendQuestion: builder.mutation<void, QuestionReq>({
      query: body => ({
        url: ApiRoutes.SUPPORT_SEND_QUESTION,
        method: 'POST',
        body
      })
    })
  })
});

export const { useSendQuestionMutation } = supportApi;
