import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

type Props = {
  children: React.ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
  'data-testid'?: string;
};

export default function FormProvider({
  children,
  onSubmit,
  methods,
  'data-testid': testId
}: Props) {
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit} data-testid={testId}>
        {children}
      </form>
    </Form>
  );
}
