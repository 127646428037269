import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.52861 5.52858C7.78896 5.26823 8.21107 5.26823 8.47141 5.52858L12.4714 9.52858C12.7318 9.78892 12.7318 10.211 12.4714 10.4714C12.2111 10.7317 11.789 10.7317 11.5286 10.4714L8.00001 6.94279L4.47141 10.4714C4.21107 10.7317 3.78896 10.7317 3.52861 10.4714C3.26826 10.211 3.26826 9.78892 3.52861 9.52858L7.52861 5.52858Z"
      fill="black"
    />
  </svg>
);

export default React.memo(Icon);
