import { IncomeFrequencyEnum } from 'enums/income-frequency.enum';

function convertFromIncomeFrequency(incomeFrequency: IncomeFrequencyEnum) {
  switch (incomeFrequency) {
    case IncomeFrequencyEnum.PER_MONTH:
      return 'perMonth';

    case IncomeFrequencyEnum.PER_YEAR:
      return 'perYear';

    default:
      throw new Error('Invalid income frequency');
  }
}

function convertToIncomeFrequency(incomeFrequency: string) {
  switch (incomeFrequency) {
    case 'Per Month':
      return IncomeFrequencyEnum.PER_MONTH;

    case 'Per Year':
      return IncomeFrequencyEnum.PER_YEAR;

    default:
      throw new Error('Invalid income frequency');
  }
}

export { convertFromIncomeFrequency, convertToIncomeFrequency };
