import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99999 9.5274L13.8187 13.3452L12.5452 14.6187L10.9 12.9735V18H9.09999V12.9717L7.45479 14.6187L6.18129 13.3452L9.99999 9.5274ZM9.99999 1.99944e-08C11.5453 7.35929e-05 13.0366 0.568002 14.1906 1.59581C15.3445 2.62361 16.0805 4.03962 16.2586 5.5746C17.3784 5.87998 18.3553 6.56919 19.0186 7.52178C19.6818 8.47436 19.9892 9.62972 19.887 10.786C19.7849 11.9422 19.2796 13.0257 18.4596 13.8472C17.6396 14.6687 16.5569 15.1759 15.4009 15.2802V13.4676C15.815 13.4085 16.2133 13.2674 16.5723 13.0527C16.9314 12.8379 17.244 12.5539 17.4921 12.217C17.7402 11.8801 17.9186 11.4972 18.0171 11.0906C18.1155 10.6839 18.132 10.2618 18.0655 9.84876C17.9991 9.43572 17.851 9.04005 17.6299 8.68486C17.4089 8.32967 17.1193 8.02208 16.7781 7.78002C16.4369 7.53797 16.0508 7.36631 15.6425 7.27507C15.2343 7.18383 14.8119 7.17483 14.4001 7.2486C14.541 6.5924 14.5334 5.91297 14.3778 5.2601C14.2222 4.60722 13.9224 3.99743 13.5006 3.47538C13.0788 2.95333 12.5455 2.53225 11.9399 2.24298C11.3343 1.9537 10.6716 1.80357 10.0004 1.80357C9.32927 1.80357 8.6666 1.9537 8.06096 2.24298C7.45533 2.53225 6.92207 2.95333 6.50025 3.47538C6.07843 3.99743 5.77873 4.60722 5.62309 5.2601C5.46746 5.91297 5.45984 6.5924 5.60079 7.2486C4.77968 7.0944 3.93095 7.27271 3.2413 7.74429C2.55165 8.21586 2.07759 8.94209 1.92339 9.7632C1.76919 10.5843 1.9475 11.433 2.41908 12.1227C2.89065 12.8123 3.61688 13.2864 4.43799 13.4406L4.59999 13.4676V15.2802C3.4439 15.1761 2.36116 14.669 1.54101 13.8476C0.720866 13.0261 0.215484 11.9426 0.113197 10.7863C0.0109092 9.63009 0.318222 8.47466 0.981422 7.522C1.64462 6.56934 2.62153 5.88005 3.74139 5.5746C3.91933 4.03954 4.65525 2.62342 5.80921 1.59558C6.96317 0.567738 8.45465 -0.000123032 9.99999 1.99944e-08Z"
      fill="#525866"
    />
  </svg>
);

export default React.memo(Icon);
