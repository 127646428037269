import {
  clearAuthData,
  clearChecksData,
  clearDashboardData,
  clearPropertiesData
} from 'store/clear-data';
import { useAppDispatch } from './redux-hooks';

type ReturnType = {
  handleLogOut: () => void;
};

export const useLogout = (): ReturnType => {
  const dispatch = useAppDispatch();

  const handleLogOut = () => {
    dispatch(clearAuthData());
    dispatch(clearDashboardData());
    dispatch(clearPropertiesData());
    dispatch(clearChecksData());
  };

  return { handleLogOut };
};
