import { Navigate } from 'react-router-dom';

import { useAppSelector } from 'hooks';
import { AppRoutes } from 'enums';

type Props = {
  children: JSX.Element;
};

export function PublicRoute({ children }: Props): JSX.Element {
  const { accessToken, isEmailVerified, city, isCongratulationModal } = useAppSelector(
    state => state.auth
  );

  return accessToken && isEmailVerified && city && !isCongratulationModal ? (
    <Navigate to={AppRoutes.DASHBOARD_PAGE} replace={true} />
  ) : (
    <>{children}</>
  );
}
