import { useFormContext, Controller } from 'react-hook-form';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { useTheme } from 'hooks';

type Props = TextFieldProps & {
  name: string;
};

export default function RHFTextField({ name, helperText, type, sx, ...other }: Props) {
  const { control } = useFormContext();
  const { palette } = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          onChange={event => {
            if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(event.target.value);
            }
          }}
          sx={{
            ...sx,
            input: {
              height: '35px',
              border: `1px solid ${error ? palette.common.error : palette.common.disabled}`,
              color: palette.common.black,
              borderRadius: '10px',
              padding: '1.5px 10px !important',
              '&:-webkit-autofill': {
                backgroundColor: `${palette.common.white} !important`,
                boxShadow: `0 0 0 50px transparent inset !important`,
                WebkitTextFillColor: `${palette.common.black} !important`
              },
              '&:hover, &:focus': {
                border: `1px solid ${palette.common.gold}`
              }
            },
            '::placeholder': {
              color: palette.common.disabled
            },
            '& .MuiFormHelperText-root': {
              fontSize: '12px',
              marginBottom: '5px'
            }
          }}
          InputProps={{ disableUnderline: true }}
          inputProps={{ 'data-testid': name }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          variant="standard"
          {...other}
        />
      )}
    />
  );
}
