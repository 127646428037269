import { TypographyVariantsOptions } from '@mui/material/styles';

import { FontFamily } from 'types/config';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    formTitle: React.CSSProperties;
    itemField: React.CSSProperties;
    helperText: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    formTitle?: React.CSSProperties;
    itemField?: React.CSSProperties;
    helperText?: React.CSSProperties;
    checkTextLabel?: React.CSSProperties;
    checkTextValue?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    formTitle: true;
    itemField: true;
    helperText: true;
    checkTextLabel: true;
    checkTextValue: true;
  }
}

const Typography = (fontFamily: FontFamily): TypographyVariantsOptions => ({
  fontFamily,
  h2: {
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: 1.6,
    color: '#0E121B'
  },
  h3: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: 1.6,
    color: '#0E121B'
  },
  h4: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: 1.5
  },
  h5: {
    fontSize: '17px',
    fontWeight: 500,
    lineHeight: 1.6,
    textTransform: 'none'
  },
  body1: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: 1.6
  },
  body2: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 1.6
  },
  caption: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: 1.2,
    textTransform: 'none',
    color: '#525866'
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: 1.25,
    color: '#0E121B'
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: 1.3
  },
  button: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: 1.6,
    textTransform: 'none'
  },
  formTitle: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: 1.4
  },
  itemField: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: 1.6
  },
  helperText: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: 1.6
  },
  checkTextLabel: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.6
  },
  checkTextValue: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: 1.6
  }
});

export default Typography;
