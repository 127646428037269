import { BaseQueryApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ApiRoutes } from 'enums';
import { RootState } from 'store';
import { ERROR_STATUSES, STATUS_CODES } from 'constants/error-statuses';

import { clearAuthData, setAuthData } from './auth';
import { IUnauthorizedError } from './auth/types';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken;

    if (token) headers.set('Authorization', `Bearer ${token}`);

    return headers;
  }
});

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (
    (result?.error as IUnauthorizedError)?.data?.statusCode === STATUS_CODES.UNAUTHORIZED &&
    (result?.error as IUnauthorizedError)?.data?.error === ERROR_STATUSES.JWT_EXPIRED
  ) {
    const refreshToken = (api.getState() as RootState).auth.refreshToken;

    const refreshResult = await baseQuery(
      { url: ApiRoutes.REFRESH_ACCESS, method: 'POST', body: { refreshToken } },
      api,
      extraOptions
    );

    if (refreshResult?.data) {
      api.dispatch(setAuthData({ ...refreshResult.data }));

      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(clearAuthData());
    }
  }

  return result;
};

export { baseQueryWithReauth };
