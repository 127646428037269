import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authReducer, authApi } from './auth';
import { propertiesApi, propertiesReducer } from './properties';
import { dashboardApi, dashboardReducer, bulkResModalReducer } from './dashboard';
import { profileApi } from './profile';
import { plaidApi } from './plaid';
import { checksReducer } from './checks';
import { supportApi } from './support';
import { transUnionApi } from './trans-union';
import { shareableApi } from './shareable';
import { payscoreApi } from './payscore';

const persistConfig = {
  key: 'store',
  whitelist: ['auth', 'properties', 'dashboard'],
  storage
};

export const rootReducer = combineReducers({
  auth: authReducer,
  properties: propertiesReducer,
  dashboard: dashboardReducer,
  bulkRes: bulkResModalReducer,
  checks: checksReducer,
  [authApi.reducerPath]: authApi.reducer,
  [propertiesApi.reducerPath]: propertiesApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [plaidApi.reducerPath]: plaidApi.reducer,
  [transUnionApi.reducerPath]: transUnionApi.reducer,
  [shareableApi.reducerPath]: shareableApi.reducer,
  [payscoreApi.reducerPath]: payscoreApi.reducer,
  [supportApi.reducerPath]: supportApi.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    })
      .concat(authApi.middleware)
      .concat(propertiesApi.middleware)
      .concat(dashboardApi.middleware)
      .concat(profileApi.middleware)
      .concat(plaidApi.middleware)
      .concat(transUnionApi.middleware)
      .concat(shareableApi.middleware)
      .concat(payscoreApi.middleware)
      .concat(supportApi.middleware)
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
setupListeners(store.dispatch);
