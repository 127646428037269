import { TextField, styled } from '@mui/material';

export const StyledTextField = styled(TextField)`
  width: 312px;
  margin-right: -15px;
  margin-left: -7px;

  input {
    padding: 9px 24px 9px 50px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.palette.common.northSeaBlue};
    color: ${({ theme }) => theme.palette.common.white};

    &::placeholder {
      color: ${({ theme }) => theme.palette.common.thunderCloud};
    }
  }
`;
