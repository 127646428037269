export function generateMonthArray(numMonths: number) {
  const monthArray = Array.from({ length: numMonths }, (_, index) => {
    const monthLabel = index === 0 ? 'month' : 'months';

    return `${index + 1} ${monthLabel}`;
  });

  return monthArray;
}

export function getNumberOfMonths(monthString: string): number {
  const parts = monthString.split(' ');

  return parseInt(parts[0], 10);
}

enum MonthValues {
  MONTH = 'month',
  MONTHS = 'months'
}

export function formatMonthLabel(numMonths: number): string {
  if (numMonths === 1) {
    return `${numMonths} ${MonthValues.MONTH}`;
  } else {
    return `${numMonths} ${MonthValues.MONTHS}`;
  }
}
