import { Controller, useFormContext } from 'react-hook-form';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormHelperText } from '@mui/material';

import { useTheme } from 'hooks';
import { SVGIcon } from 'components';

type Props = OutlinedInputProps & {
  name: string;
};

export function RHFDatePicker({ name, placeholder }: Props) {
  const { control } = useFormContext();
  const { palette } = useTheme();

  const daysFormatter = (day: string, date: Date) => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayIndex = date.getDay();

    return daysOfWeek[dayIndex];
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <DatePicker
            {...field}
            format="MMMM dd, yyyy"
            maxDate={new Date()}
            dayOfWeekFormatter={daysFormatter}
            slotProps={{
              textField: {
                placeholder,
                error: !!error,
                InputProps: {
                  sx: {
                    height: '40px',
                    borderColor: 'lightgray',
                    '& fieldset': {
                      borderColor: 'lightgray'
                    },
                    '&:hover fieldset': {
                      borderColor: 'gray'
                    }
                  }
                }
              }
            }}
            slots={{
              openPickerIcon: () => (
                <div style={{ marginRight: '18px' }}>
                  <SVGIcon name="calendar" />
                </div>
              )
            }}
          />
          {error && (
            <FormHelperText sx={{ color: palette.common.error, fontSize: '12px' }}>
              {error.message}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
}
