import { Theme } from '@mui/material/styles';

export default function OutlinedInput(theme: Theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '16px 20px',
          fontWeight: 500,
          lineHeight: 1.6,
          fontSize: 14,
          color: theme.palette.common.black,
          '&::placeholder': {
            color: theme.palette.common.thunderCloud,
            opacity: 1
          },
          '&.Mui-error': {
            borderColor: theme.palette.common.error
          },
          '&[type="number"]::-webkit-outer-spin-button, &[type="number"]::-webkit-inner-spin-button':
            {
              WebkitAppearance: 'none'
            },
          '&[type="number"], &[type="number"]:hover, &[type="number"]:focus': {
            appearance: 'none',
            MozAppearance: 'textfield'
          },
          '&:-webkit-autofill': {
            backgroundColor: `${theme.palette.common.dark} !important`,
            boxShadow: `0 0 0 50px ${theme.palette.common.dark} inset !important`,
            WebkitTextFillColor: `${theme.palette.common.black} !important`
          }
        },
        notchedOutline: {
          borderColor: theme.palette.common.thunderCloud,
          borderRadius: '10px'
        },
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.common.gold
          },
          '&.Mui-focused fieldset': {
            '&.MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.common.gold}`
            }
          },
          '&.Mui-error': {
            color: theme.palette.common.error
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            color: `${theme.palette.common.error} !important`,
            border: `1px solid ${theme.palette.common.thunderCloud}`,
            borderRadius: '10px'
          },
          '&.Mui-disabled .MuiInputBase-input': {
            WebkitTextFillColor: theme.palette.common.thunderCloud
          }
        }
      }
    }
  };
}
