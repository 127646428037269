import { Stack, Typography } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { t } from 'i18next';

import { useAppSelector, useTheme, useRef, useState, useNavigate, useLogout } from 'hooks';
import { Avatar, SVGIcon } from 'components';
import { AppRoutes } from 'enums';

import {
  StyledBackdrop,
  StyledButton,
  StyledMenuItem,
  StyledPopper,
  StyledPopperDialog,
  StyledProfileStack
} from './styles';

export function ProfileBar(): JSX.Element {
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);

  const { palette } = useTheme();
  const navigate = useNavigate();
  const stackRef = useRef<HTMLDivElement>(null);

  const { handleLogOut } = useLogout();
  const { firstName, lastName, avatarRelativeUrl, role } = useAppSelector(state => state.auth);

  const handleTogglePopper = (): void => {
    setIsPopperOpen(!isPopperOpen);
  };

  const handleEditProfile = (): void => {
    navigate(AppRoutes.EDIT_PROFILE_PAGE);
    handleTogglePopper();
  };

  const logOut = (): void => {
    handleTogglePopper();
    handleLogOut();
  };

  const handleClickAway = (): void => {
    if (isPopperOpen) setIsPopperOpen(false);
  };

  return (
    <>
      {isPopperOpen && <StyledBackdrop open />}

      <ClickAwayListener onClickAway={handleClickAway}>
        <StyledProfileStack ref={stackRef}>
          {firstName && lastName && (
            <Avatar
              firstName={firstName}
              lastName={lastName}
              avatarUrl={avatarRelativeUrl}
              sx={{ mr: '12px' }}
            />
          )}

          <Stack mr="5px">
            <Typography variant="caption" color={palette.common.black}>
              {firstName}
            </Typography>

            <Typography variant="body2" color={palette.common.thunderCloud}>
              {t(`header.${role}Role`)}
            </Typography>
          </Stack>

          <StyledButton onClick={handleTogglePopper}>
            <SVGIcon name="chevronDown" stroke={palette.common.black} width="10px" height="10px" />
          </StyledButton>

          <StyledPopper
            open={isPopperOpen}
            disablePortal
            anchorEl={stackRef.current}
            placement={'bottom-start'}
          >
            <StyledPopperDialog>
              <StyledMenuItem onClick={handleEditProfile}>
                {t('header.editProfileButton')}
              </StyledMenuItem>

              <StyledMenuItem onClick={logOut}>{t('header.logOutButton')}</StyledMenuItem>
            </StyledPopperDialog>
          </StyledPopper>
        </StyledProfileStack>
      </ClickAwayListener>
    </>
  );
}
