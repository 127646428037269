import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from 'store/api-base-query';
import { ApiRoutes } from 'enums';

export const payscoreApi = createApi({
  reducerPath: 'PayscoreApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    initializePayscore: builder.mutation<any, string>({
      query: applicationId => ({
        url: `${ApiRoutes.PAYSCORE_INITIALIZATION}/${applicationId}`,
        method: 'POST'
      })
    })
  })
});

export const { useInitializePayscoreMutation } = payscoreApi;
