enum ApplicationsOrders {
  LESSOR_NAME = 'lessorFullName',
  LESSOR_EMAIL = 'lessorEmail',
  LESSOR_PHONE = 'lessorPhone',
  TENANT_NAME = 'tenantFullName',
  TENANT_EMAIL = 'tenantEmail',
  TENANT_PHONE = 'tenantPhone',
  PROPERTY_NAME = 'propertyName',
  CREATED_DATA = 'createdDate'
}

export { ApplicationsOrders };
