import { Typography } from '@mui/material';
import { t } from 'i18next';

import { useEffect, useRef } from 'hooks';

import {
  StyledDialog,
  StyledDialogContent,
  StyledScrollStack,
  StyledTextStack,
  StyledTitleStack,
  Thumb
} from './styles';

type Props = {
  open: boolean;
  handleCancel: () => void;
};

export function TermsConditionsModal({ open, handleCancel }: Props): JSX.Element {
  const descriptionElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;

      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <StyledDialog open={open} onClose={handleCancel}>
      <StyledDialogContent>
        <Thumb>
          <StyledTitleStack>
            <Typography variant="h3">{t('termsConditions.title')}</Typography>
          </StyledTitleStack>

          <StyledScrollStack>
            <StyledTextStack>
              <Typography variant="h5">{t('termsConditions.mainText')}</Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="subtitle1">
                {t('termsConditions.termsAcceptanceTitle')}
              </Typography>
              <Typography variant="body2">{t('termsConditions.termsAcceptanceText')}</Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="subtitle1">{t('termsConditions.eligibilityTitle')}</Typography>
              <Typography variant="body2">{t('termsConditions.eligibilityText')}</Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="subtitle1">{t('termsConditions.servicesUseTitle')}</Typography>
              <Typography variant="body2">{t('termsConditions.servicesUseText')}</Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="subtitle1">
                {t('termsConditions.accountRegistrationTitle')}
              </Typography>
              <Typography variant="body2">
                {t('termsConditions.accountRegistrationText')}
              </Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="subtitle1">
                {t('termsConditions.userResponsibilitiesTitle')}
              </Typography>
              <Typography variant="body2">
                {t('termsConditions.userResponsibilitiesText')}
              </Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="subtitle1">
                {t('termsConditions.tenantScreeningTitle')}
              </Typography>
              <Typography variant="body2">{t('termsConditions.tenantScreeningText')}</Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="subtitle1">
                {t('termsConditions.informationUseTitle')}
              </Typography>
              <Typography variant="body2">{t('termsConditions.informationUseText')}</Typography>
              <ul>
                <li>
                  <Typography variant="body2">
                    {t('termsConditions.informationUseText1')}
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    {t('termsConditions.informationUseText2')}
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    {t('termsConditions.informationUseText3')}
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    {t('termsConditions.informationUseText4')}
                  </Typography>
                </li>
              </ul>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="subtitle1">{t('termsConditions.dataSecurityTitle')}</Typography>
              <Typography variant="body2">{t('termsConditions.dataSecurityText')}</Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="subtitle1">
                {t('termsConditions.intellectualPropertyTitle')}
              </Typography>
              <Typography variant="body2">
                {t('termsConditions.intellectualPropertyText')}
              </Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="subtitle1">
                {t('termsConditions.limitationOfLiabilityTitle')}
              </Typography>
              <Typography variant="body2">
                {t('termsConditions.limitationOfLiabilityText')}
              </Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="subtitle1">
                {t('termsConditions.indemnificationTitle')}
              </Typography>
              <Typography variant="body2">{t('termsConditions.indemnificationText')}</Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="subtitle1">
                {t('termsConditions.changesToTermsTitle')}
              </Typography>
              <Typography variant="body2">{t('termsConditions.changesToTermsText')}</Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="subtitle1">{t('termsConditions.governingLawTitle')}</Typography>
              <Typography variant="body2">{t('termsConditions.governingLawText')}</Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="subtitle1">{t('termsConditions.contactUsTitle')}</Typography>
              <ul>
                <li>
                  <Typography variant="body2">{t('termsConditions.contactUsItem1')}</Typography>
                </li>
                <li>
                  <Typography variant="body2">{t('termsConditions.contactUsItem2')}</Typography>
                </li>
                <li>
                  <Typography variant="body2">{t('termsConditions.contactUsItem3')}</Typography>
                </li>
              </ul>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="subtitle1">
                {t('termsConditions.tenantAccountsResponsibilitiesTitle')}
              </Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="checkTextValue">
                {t('termsConditions.accountCreationInformationTitle')}
              </Typography>
              <Typography variant="body2">
                {t('termsConditions.accountCreationInformationText')}
              </Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="checkTextValue">
                {t('termsConditions.useOfPersonalInformationTitle')}
              </Typography>
              <Typography variant="body2">
                {t('termsConditions.useOfPersonalInformationText')}
              </Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="checkTextValue">
                {t('termsConditions.consentToObtainReportsTitle')}
              </Typography>
              <Typography variant="body2">
                {t('termsConditions.consentToObtainReportsText')}
              </Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="checkTextValue">
                {t('termsConditions.accountDataSecurityTitle')}
              </Typography>
              <Typography variant="body2">
                {t('termsConditions.accountDataSecurityText')}
              </Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="checkTextValue">
                {t('termsConditions.userConductTitle')}
              </Typography>
              <Typography variant="body2">{t('termsConditions.userConductText')}</Typography>
              <ul>
                <li>
                  <Typography variant="body2">
                    {t('termsConditions.userConductListItem1')}
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    {t('termsConditions.userConductListItem2')}
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    {t('termsConditions.userConductListItem3')}
                  </Typography>
                </li>
              </ul>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="checkTextValue">
                {t('termsConditions.terminationOfAccountTitle')}
              </Typography>
              <Typography variant="body2">
                {t('termsConditions.terminationOfAccountText')}
              </Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="subtitle1">
                {t('termsConditions.rightsAndChoicesTitle')}
              </Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="checkTextValue">
                {t('termsConditions.accessAndControlTitle')}
              </Typography>
              <Typography variant="body2">{t('termsConditions.accessAndControlText')}</Typography>
            </StyledTextStack>

            <StyledTextStack>
              <Typography variant="checkTextValue">{t('termsConditions.optOutTitle')}</Typography>
              <Typography variant="body2">{t('termsConditions.optOutText')}</Typography>
            </StyledTextStack>
          </StyledScrollStack>
        </Thumb>
      </StyledDialogContent>
    </StyledDialog>
  );
}
