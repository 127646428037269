import {
  Dialog,
  DialogContent,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
  styled
} from '@mui/material';
import { getScrollbarCSS } from 'themes/helpers';

export const StyledDialog = styled(Dialog)`
  backdrop-filter: blur(5px);
`;

export const StyledDialogContent = styled(DialogContent)`
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.common.dark};
  border: 1px solid ${({ theme }) => theme.palette.common.gold};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0;
  overflow: hidden;

  h3, h6, a {
    color: ${({ theme }) => theme.palette.common.white};
`;

export const StyledScrollStack = styled(Stack)`
  overflow-y: auto;
  max-height: 70vh;
  max-width: 80vw;
  padding: 0 32px;

  ${getScrollbarCSS()};
`;

export const StyledTitleStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
  margin-left: 58px;
`;

export const StyledTextStack = styled(Stack)`
  flex-direction: column;
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.common.gold};
  text-decoration: none;
`;

export const TextTypography = styled(Typography)`
  text-align: justify;
`;

export const TextTitleTypography = styled(Typography)`
  margin: 24px 0;
`;

export const TextSubtitleTypography = styled(Typography)`
  margin-bottom: 12px;
`;

export const StyledList = styled(List)`
  list-style-type: disc;
  padding-left: 40px;
`;

export const StyledListItem = styled(ListItem)`
  display: list-item;
  padding: 0;
`;
