enum EmploymentStatus {
  EMPLOYED = 'Employed',
  NOT_EMPLOYED = 'NotEmployed',
  SELF_EMPLOYED = 'SelfEmployed',
  STUDENT = 'Student',
  RETIRED = 'Retired',
  OTHER = 'Other'
}

export { EmploymentStatus };
