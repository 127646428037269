export function stringFormat(value?: string | null): string | null {
  if (!value) {
    return null;
  } else {
    return value
      .toLowerCase()
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
