import { ReactNode } from 'react';
import { SxProps } from '@mui/material';

import { StyledBox } from './styles';

type Props = {
  children: ReactNode;
  sx?: SxProps;
};

export function Thumb({ children, sx }: Props): JSX.Element {
  return <StyledBox sx={sx}>{children}</StyledBox>;
}
