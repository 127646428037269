import homeImg from 'components/sign-in-story/images/home-logo.png';
import { Link, useLocation } from 'react-router-dom';
import { AppRoutes, Roles } from 'enums';
import { useAppSelector } from 'hooks';
import { ProfileBar } from './components';
import { LinkBar, LinkBox, StyledBox, StyledImg, StyledLink } from './styles';

export function Header(): JSX.Element {
  const location = useLocation();
  const role = useAppSelector(state => state.auth.role);

  return (
    <StyledBox>
      <LinkBar>
        <Link to="/">
          <StyledImg src={homeImg} alt="home-logo" />
        </Link>

        <LinkBox>
          <StyledLink
            to={AppRoutes.DASHBOARD_PAGE}
            isActive={location.pathname === AppRoutes.DASHBOARD_PAGE}
          >
            Dashboard
          </StyledLink>
          {role !== Roles.TENANT && (
            <StyledLink
              to={AppRoutes.PROPERTIES_PAGE}
              isActive={location.pathname === AppRoutes.PROPERTIES_PAGE}
            >
              Property
            </StyledLink>
          )}

          <StyledLink
            to={AppRoutes.SUPPORT_PAGE}
            isActive={location.pathname === AppRoutes.SUPPORT_PAGE}
          >
            Support
          </StyledLink>
        </LinkBox>
      </LinkBar>

      <ProfileBar />
    </StyledBox>
  );
}
