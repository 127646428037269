import { useFormContext, Controller } from 'react-hook-form';
import { Theme, SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectProps } from '@mui/material/Select';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { IconButton, Tooltip } from '@mui/material';

import { useEffect, useState, useTheme } from 'hooks';
import { SVGIcon } from 'components';
import { Colors } from 'enums';

type RHFSelectProps = TextFieldProps & {
  name: string;
  native?: boolean;
  maxHeight?: boolean | number;
  children: React.ReactNode;
  PaperPropsSx?: SxProps<Theme>;
};

export function RHFSelect({
  name,
  native,
  maxHeight = 220,
  helperText,
  children,
  PaperPropsSx,
  ...other
}: RHFSelectProps) {
  const { control } = useFormContext();
  const { palette } = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          sx={{
            '& .MuiStack-root': {
              border: `1px solid ${error ? palette.common.error : palette.common.error}`
            }
          }}
          SelectProps={{
            native,
            MenuProps: {
              PaperProps: {
                sx: {
                  ...(!native && {
                    maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset'
                  }),
                  ...PaperPropsSx
                }
              }
            },
            sx: { textTransform: 'capitalize' }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}

type Option = {
  label: string;
  value: string;
  disabled: boolean;
  tipTitle?: string;
};

type RHFMultiSelectProps = SelectProps & {
  name: string;
  label?: string;
  chip?: boolean;
  checkbox?: boolean;
  placeholder?: string;
  helperText?: React.ReactNode;
  options: Option[];
};

export function RHFMultiSelect({
  name,
  chip,
  label,
  options,
  checkbox,
  placeholder,
  helperText,
  sx,
  ...other
}: RHFMultiSelectProps) {
  const { palette } = useTheme();
  const { control, setValue } = useFormContext();
  const [selectedItems, setSelectedItems] = useState<Option[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  useEffect(() => {
    setSelectedItems(options.filter(item => selectedIds.includes(item.value)));
  }, [options, selectedIds]);

  const handleRemoveItem = (value: string) => {
    const updatedItems = selectedItems.filter(item => item.value !== value);

    setSelectedItems(updatedItems);
    setValue(
      name,
      updatedItems.map(item => item.value)
    );

    setSelectedIds(updatedItems.map(item => item.value));
  };

  const renderValues = () => {
    if (!selectedItems.length && placeholder) {
      return (
        <Box component="em" sx={{ color: 'text.disabled' }}>
          {placeholder}
        </Box>
      );
    }

    if (chip) {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
          {selectedItems.map(item => (
            <Chip
              sx={{
                color: palette.common.text
              }}
              key={item.value}
              size="medium"
              label={item.label}
              onMouseDown={event => {
                event.stopPropagation();
              }}
              onDelete={() => {
                handleRemoveItem(item.value);
              }}
            />
          ))}
        </Box>
      );
    }

    return selectedItems.map(item => item.label).join(', ');
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl sx={{ ...sx }}>
          {label && <InputLabel id={name}> {label} </InputLabel>}

          <Select
            IconComponent={props => <SVGIcon name="chevronDown" width="13" height="8" {...props} />}
            {...field}
            multiple
            displayEmpty={!!placeholder}
            labelId={name}
            color="primary"
            placeholder="Select"
            sx={{
              '& fieldset': {
                borderColor: palette.common.disabled
              },
              '&:hover fieldset': {
                borderColor: palette.common.disabled
              },
              '&.Mui-focused fieldset': {
                borderColor: palette.common.disabled
              },
              '& .MuiSelect-select.MuiSelect-multiple': {
                padding: '8.5px 40px 8.5px  16px',
                border: 'none'
              },
              '& .MuiSelect-icon': {
                top: 'calc(50% - 4px)'
              }
            }}
            onChange={event => {
              const value = event.target.value;

              setSelectedIds(value);
              field.onChange(value);
            }}
            input={<OutlinedInput fullWidth label={label} error={!!error} />}
            renderValue={renderValues}
            {...other}
          >
            {placeholder && (
              <MenuItem disabled value="">
                <em> {placeholder} </em>
              </MenuItem>
            )}

            {options.map(option => {
              const selected = field.value.includes(option.value);

              return (
                <MenuItem disabled={option.disabled} key={option.value} value={option.value}>
                  {option.label}

                  {option.tipTitle && (
                    <Tooltip title={option.tipTitle} placement="bottom-start">
                      <IconButton>
                        <SVGIcon
                          name="question"
                          style={{ marginTop: '2px', fill: palette.common.thunderCloud }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  {checkbox && (
                    <Box marginLeft="auto">
                      <Checkbox
                        size="small"
                        sx={{
                          color: 'transparent',
                          '&.Mui-checked': {
                            color: Colors.DARK_BLUE
                          }
                        }}
                        disableRipple
                        checked={selected}
                      />
                    </Box>
                  )}
                </MenuItem>
              );
            })}
          </Select>

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
