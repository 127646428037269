import { Controller, useFormContext } from 'react-hook-form';
import { MuiOtpInput, MuiOtpInputProps } from 'mui-one-time-password-input';

import { useTheme } from 'hooks';

type RHFCodesProps = MuiOtpInputProps & {
  name: string;
};

export default function RHFCode({ name, ...other }: RHFCodesProps) {
  const { control } = useFormContext();
  const { palette } = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <MuiOtpInput
            {...field}
            autoFocus
            gap="16px"
            length={6}
            TextFieldsProps={{
              error: !!error,
              variant: 'standard',
              sx: {
                height: '44px',
                border: `1px solid ${error ? palette.common.error : palette.common.thunderCloud}`,
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',

                '&:hover, &:focus': {
                  border: `1px solid ${palette.common.gold}`
                }
              },
              InputProps: {
                placeholder: '0',
                sx: { color: palette.common.black },
                disableUnderline: true
              }
            }}
            {...other}
          />
        </div>
      )}
    />
  );
}
