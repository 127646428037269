export const PATTERNS = {
  NAME: /^(?:[a-zA-Z\s'\-.']{2,20})?$/,
  MIDDLE_NAME: /^(?:[a-zA-Z\s'\-.']{1,20})?$/,
  SSN: /^\d{3}-\d{2}-\d{4}$/,
  PROPERTY_NAME: /^(?!.*\s{2,})[a-zA-Z0-9,./()#" \-'\\]{0,50}$/,
  ADDRESS: /^(?!.*\s{2,})[a-zA-Z0-9,./()#" \-'\\]{0,50}$/,
  EMAIL:
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
  PASSWORD: /^.{8,}$/i,
  EDIT_PAGE_URL: /^\/properties\/edit\/[\w-]+$/,
  CHECK_RESULTS_PAGE_URL: /^\/dashboard\/background-check-results(?:\/[a-f\d-]+)?\/?$/,
  TOPIC: /^(?!.*\s{3,})(?!^\s*$)[\s\S]{3,100}$/,
  SUPPORT_TEXT_AREA: /^(?!.*\s{3,})(?!^\s*$)[\s\S]{15,400}$/,
  ADVERSE_LETTER_TEXT_AREA: /^(?!.*\s{3,})(?!^\s*$)[\s\S]{15,3000}$/,
  PHONE: /^(1|)?(\d{3})(\d{3})(\d{4})$/,
  POSTAL_CODE: /^(?:\d{5}|\d{9})$/,
  LOWER_CASE: /[a-z]/,
  UPPER_CASE: /[A-Z]/,
  SPECIAL_CHAR: /[@$!%*?&]/
};
