import { Accordion as MUIAccordion, SxProps, Theme } from '@mui/material';

import { SVGIcon } from 'components';

import { StyledAccordionDetails, StyledAccordionSummary } from './styles';
import { Colors } from 'enums';

type Props = {
  renderSummary: JSX.Element;
  renderDetails: JSX.Element;
  expanded?: boolean;
  showIcon?: boolean;
  sx?: SxProps<Theme> | undefined;
};

export function Accordion({
  renderSummary,
  renderDetails,
  sx,
  showIcon = false,
  expanded = true
}: Props): JSX.Element {
  return (
    <MUIAccordion
      sx={{
        ...sx,
        color: Colors.BLACK,
        background: 'transparent',
        paddingTop: 0,

        '&.MuiAccordionSummary-content': {
          margin: 0
        }
      }}
      defaultExpanded={expanded}
    >
      <StyledAccordionSummary
        expandIcon={showIcon ? <SVGIcon name="chevronRight" width="20" height="20" /> : null}
      >
        {renderSummary}
      </StyledAccordionSummary>

      <StyledAccordionDetails>{renderDetails}</StyledAccordionDetails>
    </MUIAccordion>
  );
}
