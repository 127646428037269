import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 4.75C4.86193 4.75 4.75 4.86193 4.75 5V9C4.75 9.13807 4.86193 9.25 5 9.25H9C9.13807 9.25 9.25 9.13807 9.25 9V5C9.25 4.86193 9.13807 4.75 9 4.75H5ZM3.25 5C3.25 4.0335 4.0335 3.25 5 3.25H9C9.9665 3.25 10.75 4.0335 10.75 5V9C10.75 9.9665 9.9665 10.75 9 10.75H5C4.0335 10.75 3.25 9.9665 3.25 9V5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 4.75C14.8619 4.75 14.75 4.86193 14.75 5V9C14.75 9.13807 14.8619 9.25 15 9.25H19C19.1381 9.25 19.25 9.13807 19.25 9V5C19.25 4.86193 19.1381 4.75 19 4.75H15ZM13.25 5C13.25 4.0335 14.0335 3.25 15 3.25H19C19.9665 3.25 20.75 4.0335 20.75 5V9C20.75 9.9665 19.9665 10.75 19 10.75H15C14.0335 10.75 13.25 9.9665 13.25 9V5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 14.75C4.86193 14.75 4.75 14.8619 4.75 15V19C4.75 19.1381 4.86193 19.25 5 19.25H9C9.13807 19.25 9.25 19.1381 9.25 19V15C9.25 14.8619 9.13807 14.75 9 14.75H5ZM3.25 15C3.25 14.0335 4.0335 13.25 5 13.25H9C9.9665 13.25 10.75 14.0335 10.75 15V19C10.75 19.9665 9.9665 20.75 9 20.75H5C4.0335 20.75 3.25 19.9665 3.25 19V15Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 14.75C14.8619 14.75 14.75 14.8619 14.75 15V19C14.75 19.1381 14.8619 19.25 15 19.25H19C19.1381 19.25 19.25 19.1381 19.25 19V15C19.25 14.8619 19.1381 14.75 19 14.75H15ZM13.25 15C13.25 14.0335 14.0335 13.25 15 13.25H19C19.9665 13.25 20.75 14.0335 20.75 15V19C20.75 19.9665 19.9665 20.75 19 20.75H15C14.0335 20.75 13.25 19.9665 13.25 19V15Z"
      fill="white"
    />
  </svg>
);

export default React.memo(Icon);
