import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQueryWithReauth } from 'store/api-base-query';
import { ApiRoutes } from 'enums';
import { EditProfileReq, UserLong } from 'types';

export const profileApi = createApi({
  reducerPath: 'ProfileApi',
  tagTypes: ['Profile'],
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    getProfile: builder.query<UserLong, void>({
      query: () => ({
        url: ApiRoutes.PROFILE,
        method: 'GET'
      }),
      providesTags: ['Profile']
    }),
    getFile: builder.query({
      query: id => ({
        url: id,
        method: 'GET',
        responseHandler: async response => await response.blob(),
        cache: 'no-cache'
      })
    }),
    editAvatar: builder.mutation<UserLong, FormData>({
      query: body => ({
        url: ApiRoutes.PROFILE,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['Profile']
    }),
    editProfile: builder.mutation<UserLong, EditProfileReq>({
      query: body => ({
        url: ApiRoutes.PROFILE,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['Profile']
    }),
    deleteProfile: builder.mutation<void, void>({
      query: () => ({
        url: ApiRoutes.PROFILE,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetProfileQuery,
  useGetFileQuery,
  useEditAvatarMutation,
  useEditProfileMutation,
  useDeleteProfileMutation
} = profileApi;
