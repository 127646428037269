import { lazy } from 'react';

import { MainLayout } from 'layout';
import { Loadable } from 'components';
import { AppRoutes } from 'enums';

import { PrivateRoute } from './private-route';

const PropertiesPage = Loadable(lazy(() => import('pages/properties')));
const DashboardPage = Loadable(lazy(() => import('pages/dashboard')));
const SupportPage = Loadable(lazy(() => import('pages/support')));
const EditProfilePage = Loadable(lazy(() => import('pages/edit-profile')));
const BackgroundChecksPage = Loadable(lazy(() => import('pages/background-checks')));

export const MainRoutes = {
  element: (
    <PrivateRoute>
      <MainLayout />
    </PrivateRoute>
  ),
  children: [
    {
      path: AppRoutes.PROPERTIES_PAGE,
      element: <PropertiesPage />
    },
    {
      path: AppRoutes.DASHBOARD_PAGE,
      element: <DashboardPage />
    },
    {
      path: AppRoutes.SUPPORT_PAGE,
      element: <SupportPage />
    },
    {
      path: AppRoutes.EDIT_PROFILE_PAGE,
      element: <EditProfilePage />
    },
    {
      path: AppRoutes.BACKGROUND_CHECKS_DYNAMIC,
      element: <BackgroundChecksPage />
    }
  ]
};
