import { EmploymentStatus } from 'enums/employment-status.enum';

/**
 * This map converts string representations of employment statuses to their corresponding EmploymentStatus enum values.
 * Each key is a string representing the employment status, and each value is the corresponding EmploymentStatus enum value.
 */
const stringToEmploymentStatusMap = new Map<string, EmploymentStatus>([
  ['Employed', EmploymentStatus.EMPLOYED],
  ['Unemployed', EmploymentStatus.NOT_EMPLOYED],
  ['Self-employed', EmploymentStatus.SELF_EMPLOYED],
  ['Student', EmploymentStatus.STUDENT],
  ['Retired', EmploymentStatus.RETIRED],
  ['Other', EmploymentStatus.OTHER]
]);

function convertToEmploymentEnum(status: string): EmploymentStatus {
  const result = stringToEmploymentStatusMap.get(status);

  if (result === undefined) {
    throw new Error('Invalid employment status');
  }

  return result;
}

/**
 * This map converts EmploymentStatus enum values to their corresponding string representations.
 * Each key is an EmploymentStatus enum value, and each value is the corresponding string.
 */
const employmentStatusToStringMap = new Map<EmploymentStatus, string>([
  [EmploymentStatus.EMPLOYED, 'employed'],
  [EmploymentStatus.NOT_EMPLOYED, 'unemployed'],
  [EmploymentStatus.SELF_EMPLOYED, 'selfEmployed'],
  [EmploymentStatus.STUDENT, 'student'],
  [EmploymentStatus.RETIRED, 'retired'],
  [EmploymentStatus.OTHER, 'other']
]);

function convertFromEmploymentEnum(enumValue: EmploymentStatus): string {
  const result = employmentStatusToStringMap.get(enumValue);

  if (result === undefined) {
    throw new Error('Invalid employment status');
  }

  return result;
}

export { convertToEmploymentEnum, convertFromEmploymentEnum };
