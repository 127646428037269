import { Box, styled } from '@mui/material';

export const StyledBox = styled(Box)`
  width: 50%;
  display: flex;
  position: relative;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.common.goldGradient};
  color: ${({ theme }) => theme.palette.common.white};
`;
