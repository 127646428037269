import { createRoot } from 'react-dom/client';

import 'locales/i18n';

import { ConfigProvider } from 'config/config-context';
import reportWebVitals from './reportWebVitals';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <ConfigProvider>
    <App />
  </ConfigProvider>
);

reportWebVitals();
