enum ProfileFields {
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phone',
  DATE_OF_BIRTH = 'birthDate',
  SSN = 'ssn',
  EMPLOYMENT_STATUS = 'employmentStatus',
  INCOME = 'income',
  INCOME_FREQUENCY = 'incomeFrequency',
  OTHER_INCOME = 'otherIncome',
  OTHER_INCOME_FREQUENCY = 'otherIncomeFrequency',
  ASSETS = 'assets',
  COUNTRY = 'country',
  STATE = 'state',
  CITY = 'city',
  ADDRESS_LINE_1 = 'addressLine1',
  ADDRESS_LINE_2 = 'addressLine2',
  POSTAL_CODE = 'postalCode'
}

export { ProfileFields };
