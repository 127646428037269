import billCheck from './bill-check';
import emptyCircle from './empty-circle';
import search from './search';
import dashboard from './dashboard';
import property from './property';
import support from './support';
import sort from './sort';
import chevronUp from './chevron-up';
import chevronDown from './chevron-down';
import chevronLeft from './chevron-left';
import chevronRight from './chevron-right';
import cancel from './cancel';
import fileError from './file-error';
import fileSuccess from './file-success';
import upload from './upload';
import calendar from './calendar';
import diploma from './diploma';
import donut from './donut';
import profile from './profile';
import building from './building';
import creditCard from './credit-card';
import question from './question';
import mail from './mail';
import doneReport from './done-report';
import copyIcon from './copy';
import exclamationMark from './exclamation-mark';
import downloadIcon from './download';
import plusIcon from './plus-icon';
import bulkFolder from './bulk-request';
import checkIcon from './check-icon';

export const icons = {
  dashboard,
  property,
  support,
  sort,
  search,
  chevronUp,
  chevronDown,
  chevronLeft,
  chevronRight,
  billCheck,
  emptyCircle,
  cancel,
  fileError,
  fileSuccess,
  upload,
  diploma,
  donut,
  calendar,
  profile,
  building,
  creditCard,
  question,
  mail,
  doneReport,
  copyIcon,
  exclamationMark,
  downloadIcon,
  plusIcon,
  checkIcon,
  bulkFolder
};

export type IconTypes = keyof typeof icons;
