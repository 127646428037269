const PUBLIC_DATA = {
  WEB_SITE: 'www.rntrcheck.com',
  EMAIL: 'info@rntrcheck.com',
  PHONE: '(888) 345-1605',
  ADDRESS: '11900 N.E. 1st Street, Suite 300, Building G, Bellevue, WA 98005'
};

const { WEB_SITE, EMAIL, PHONE, ADDRESS } = PUBLIC_DATA;

const PUBLIC_DATA_LINKS = {
  WEB_SITE_LINK: `http://${WEB_SITE}`,
  EMAIL_LINK: `mailto:${EMAIL}`,
  PHONE_LINK: `tel:${PHONE}`,
  ADDRESS_LINK: 'https://maps.app.goo.gl/GxpTPnDEc8HGiAu79'
};

const { WEB_SITE_LINK, EMAIL_LINK, PHONE_LINK, ADDRESS_LINK } = PUBLIC_DATA_LINKS;

export { WEB_SITE, EMAIL, PHONE, ADDRESS, WEB_SITE_LINK, EMAIL_LINK, PHONE_LINK, ADDRESS_LINK };
