import { CSSProperties } from 'react';
import { createTheme } from '@mui/material/styles';
import { Colors } from 'enums';

type Color = CSSProperties['color'];

declare module '@mui/material/styles' {
  interface CommonColors {
    gold: Color;
    goldGradient: Color;
    dark: Color;
    error: Color;
    disabled: Color;
    thunderCloud: Color;
    northSeaBlue: Color;
    blueReflection: Color;
    darkKnight: Color;
    statusActive: Color;
    statusInactive: Color;
    text: Color;
  }
}

const Palette = () =>
  createTheme({
    palette: {
      common: {
        white: Colors.WHITE,
        gold: Colors.DARK_BLUE,
        goldGradient: Colors.DARK_BLUE_GRADIENT,
        dark: Colors.DARK,
        error: Colors.ERROR_COLOR,
        disabled: Colors.DISABLED,
        thunderCloud: Colors.THUNDER_CLOUD,
        northSeaBlue: Colors.NORTH_SEA_BLUE,
        blueReflection: Colors.BLUE_REFLECTION,
        darkKnight: Colors.DARK_KNIGHT,
        statusActive: Colors.STATUS_ACTIVE,
        statusInactive: Colors.STATUS_INACTIVE,
        text: '#525866'
      }
    }
  });

export default Palette;
