enum AppRoutes {
  SELECT_ROLE_PAGE = '/',
  LOG_IN_PAGE = '/login',
  SIGN_UP_PAGE = '/signup',
  VERIFY_EMAIL_PAGE = '/verify-email',
  FORGOT_PASSWORD_PAGE = '/forgot-password',
  NEW_PASSWORD_PAGE = '/new-password',
  DASHBOARD_PAGE = '/dashboard',
  PROPERTIES_PAGE = '/properties',
  ADD_PROPERTY = '/properties/add',
  EDIT_PROPERTY = '/properties/edit',
  EDIT_PROPERTY_DYNAMIC = '/properties/edit/:id',
  EDIT_PROFILE_PAGE = '/profile/edit',
  PROFILE_PAGE = '/profile',
  SUPPORT_PAGE = '/support',
  BACKGROUND_CHECKS_PAGE = '/dashboard/background-check-results',
  BACKGROUND_CHECKS_DYNAMIC = '/dashboard/background-check-results/:id'
}

export { AppRoutes };
