import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.66669 8H13.3334" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8 13.3332V2.6665" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export default React.memo(Icon);
