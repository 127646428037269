// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function Accordion(theme: Theme) {
  return {
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
        square: true,
        elevation: 0
      },
      styleOverrides: {
        root: {
          border: 'none',
          '&:not(:last-child)': {
            borderBottom: 0
          },
          backgroundColor: theme.palette.common.dark,
          color: theme.palette.common.white,
          width: '100%',
          padding: '24px',

          '&:before': {
            display: 'none'
          },
          '&.Mui-disabled': {
            // backgroundColor: theme.palette.secondary.lighter
          }
        },
        detailRoot: {
          border: 'none'
        }
      }
    }
  };
}
