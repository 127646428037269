export const STATUS_CODES = {
  UNAUTHORIZED: 401
};

export const ERROR_STATUSES = {
  VALIDATION_EXCEPTION: 'ValidationException',
  USER_ALREADY_EXIST: 'UserAlreadyExistsException',
  USER_NOT_FOUND: 'UserNotFoundByEmailException',
  INCORRECT_CREDENTIALS: 'IncorrectEmailOrPasswordException',
  OTP_ALREADY_SENT: 'OtpIsAlreadySentException',
  OTP_NOT_VALID: 'OtpIsNotValidException',
  EMAIL_ALREADY_VERIFIED: 'EmailIsAlreadyVerifiedException',
  JWT_EXPIRED: 'JwtExpiredException',
  JWT_INVALID: 'InvalidJwtException',
  JWT_INVALID_TYPE: 'InvalidJwtTypeException',
  UNAUTHORIZED_EXCEPTION: 'UnauthorizedException',
  APPLICATION_STATUS_INACTIVE: 'ApplicationCantBeCreatedOnInactivePropertyException',
  PROFILE_DELETE_FAILED: 'UserDeletionFailedException'
};
