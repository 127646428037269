import { useState, useEffect } from 'hooks';

const TIMER_DELAY_IN_SECONDS: number = 60;
const COUNT_OF_CHARACTERS: number = 2;
const DECIMAL_SYSTEM: number = 10;
const PATTERN: string = '0';
const ONE_SECOND: number = 1000;

const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedTime = `${String(minutes).padStart(COUNT_OF_CHARACTERS, PATTERN)}:${String(remainingSeconds).padStart(COUNT_OF_CHARACTERS, PATTERN)}`;

  return formattedTime;
};

type Timer = {
  formattedTimeRemaining: string;
  startTimer: () => void;
  stopTimer: () => void;
  isExpired: boolean;
};

export const useCountdownTimer = (): Timer => {
  const storedStartTime = localStorage.getItem('countdownStartTime');
  const initialStartTime = storedStartTime ? parseInt(storedStartTime, DECIMAL_SYSTEM) : null;

  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const [startTime, setStartTime] = useState<number | null>(initialStartTime);
  const [isExpired, setIsExpired] = useState<boolean>(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const updateTimer = (): void => {
      if (startTime !== null) {
        const elapsedSeconds = Math.floor((Date.now() - startTime) / ONE_SECOND);
        const remaining = Math.max(0, TIMER_DELAY_IN_SECONDS - elapsedSeconds);

        setTimeRemaining(remaining);

        if (remaining === 0) {
          clearInterval(intervalId);
          setIsExpired(true);
        }
      }
    };

    intervalId = setInterval(updateTimer, ONE_SECOND);

    return () => {
      clearInterval(intervalId);
    };
  }, [startTime]);

  const startTimer = (): void => {
    const currentTime = Date.now();

    localStorage.setItem('countdownStartTime', String(currentTime));
    setStartTime(currentTime);
    setIsExpired(false);
  };

  const stopTimer = (): void => {
    localStorage.removeItem('countdownStartTime');
    setStartTime(null);
    setIsExpired(true);
  };

  return {
    formattedTimeRemaining: formatTime(timeRemaining || 0),
    startTimer,
    stopTimer,
    isExpired
  };
};
