import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9008 4.07781L9.26164 1.70254C8.50973 1.02583 8.13378 0.68747 7.67249 0.510457L7.66634 2.33345C7.66634 3.90479 7.66634 4.69047 8.1545 5.17862C8.64265 5.66678 9.42833 5.66678 10.9997 5.66678H13.3864C13.1447 5.1973 12.7119 4.8078 11.9008 4.07781Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.66634 13.6667H8.33301C10.8472 13.6667 12.1042 13.6667 12.8853 12.8857C13.6663 12.1046 13.6663 10.8475 13.6663 8.33337V8.04197C13.6663 7.4601 13.6663 7.02296 13.638 6.66678H10.9997L10.9364 6.66678C10.205 6.66684 9.55865 6.66689 9.03764 6.59684C8.4729 6.52091 7.90824 6.34658 7.44739 5.88573C6.98654 5.42488 6.81221 4.86022 6.73628 4.29548C6.66623 3.77447 6.66628 3.12809 6.66634 2.39669L6.6725 0.507085C6.67268 0.452126 6.67738 0.397769 6.68635 0.344481C6.41394 0.333374 6.09021 0.333374 5.6862 0.333374C3.1588 0.333374 1.89511 0.333374 1.11406 1.11442C0.333008 1.89547 0.333008 3.15255 0.333008 5.66671V8.33337C0.333008 10.8475 0.333008 12.1046 1.11406 12.8857C1.89511 13.6667 3.15218 13.6667 5.66634 13.6667ZM4.32437 11.6981C4.5167 11.8785 4.81598 11.8785 5.00831 11.6981L6.34165 10.4481C6.5431 10.2593 6.55331 9.94286 6.36444 9.7414C6.17558 9.53995 5.85916 9.52974 5.6577 9.71861L5.16634 10.1793L5.16634 8.00004C5.16634 7.7239 4.94248 7.50004 4.66634 7.50004C4.3902 7.50004 4.16634 7.7239 4.16634 8.00004V10.1793L3.67498 9.71861C3.47352 9.52974 3.1571 9.53995 2.96824 9.7414C2.77937 9.94286 2.78958 10.2593 2.99104 10.4481L4.32437 11.6981Z"
      fill="white"
    />
  </svg>
);

export default React.memo(Icon);
