/* eslint-disable padding-line-between-statements */
import { t } from 'i18next';
import { useSnackbar } from 'notistack';

import { ERROR_STATUSES } from 'constants/error-statuses';
import { VariantValues } from 'enums';

interface IUseAuthErrorHandler {
  getSuccessSnackbar: (message: string) => void;
  getErrorSnackbar: (message: string) => void;
}

export function useAuthErrorHandler(): IUseAuthErrorHandler {
  const { enqueueSnackbar } = useSnackbar();

  function getSuccessSnackbar(message: string) {
    enqueueSnackbar(message, { variant: VariantValues.SUCCESS });
  }

  function getErrorSnackbar(error: any) {
    if ('data' in error && 'error' in error.data) {
      const errorStatus = error.data.error;

      switch (errorStatus) {
        case ERROR_STATUSES.JWT_EXPIRED:
        case ERROR_STATUSES.JWT_INVALID:
        case ERROR_STATUSES.JWT_INVALID_TYPE:
          enqueueSnackbar(t('errors.sessionExpiredAuth'), { variant: VariantValues.ERROR });
          break;

        case ERROR_STATUSES.VALIDATION_EXCEPTION:
          enqueueSnackbar(t('errors.validationError'), { variant: VariantValues.ERROR });
          break;

        case ERROR_STATUSES.USER_ALREADY_EXIST:
          enqueueSnackbar(t('errors.userAlreadyExist'), { variant: VariantValues.ERROR });
          break;

        case ERROR_STATUSES.USER_NOT_FOUND:
          enqueueSnackbar(t('errors.userNotFound'), { variant: VariantValues.ERROR });
          break;

        case ERROR_STATUSES.INCORRECT_CREDENTIALS:
          enqueueSnackbar(t('errors.incorrectCredentials'), { variant: VariantValues.ERROR });
          break;

        case ERROR_STATUSES.OTP_ALREADY_SENT:
          enqueueSnackbar(t('errors.otpAlreadySent'), { variant: VariantValues.ERROR });
          break;

        case ERROR_STATUSES.OTP_NOT_VALID:
          enqueueSnackbar(t('errors.otpNotValid'), { variant: VariantValues.ERROR });
          break;

        case ERROR_STATUSES.EMAIL_ALREADY_VERIFIED:
          enqueueSnackbar(t('errors.emailAlreadyVerified'), { variant: VariantValues.ERROR });
          break;

        default:
          enqueueSnackbar(t('errors.generalError'), { variant: VariantValues.ERROR });
          break;
      }
    } else {
      enqueueSnackbar(t('errors.generalError'), { variant: VariantValues.ERROR });
    }
  }

  return { getSuccessSnackbar, getErrorSnackbar };
}
