import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation from './langs/en.json';

i18next.use(initReactI18next).init({
  lng: 'en',
  resources: {
    en: {
      translation
    }
  },
  interpolation: {
    escapeValue: false
  }
});

export { i18next };

type RecursiveKeyOf<TObj extends Record<string, any>> = {
  [TKey in keyof TObj & string]: TObj[TKey] extends Record<string, any>
    ? `${TKey}` | `${TKey}.${RecursiveKeyOf<TObj[TKey]>}`
    : `${TKey}`;
}[keyof TObj & string];

type DefaultLocale = typeof translation;
export type TxKeyPath = RecursiveKeyOf<DefaultLocale>;
