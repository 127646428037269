import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.16 1.33301H7.56389C6.38773 1.333 5.45612 1.33299 4.72703 1.43141C3.97668 1.5327 3.36935 1.7461 2.8904 2.22698C2.41144 2.70786 2.19889 3.31762 2.09801 4.07099C1.99998 4.80301 1.99999 5.73835 2 6.91924V10.8109C2 11.8163 2.6133 12.6779 3.48478 13.0392C3.43992 12.4329 3.43996 11.5821 3.44 10.8744L3.44 7.59807L3.44 7.53462C3.43995 6.68017 3.43991 5.94397 3.51886 5.35441C3.60346 4.72259 3.79426 4.11695 4.28353 3.62571C4.77281 3.13447 5.37603 2.94291 6.00532 2.85796C6.59251 2.7787 7.32578 2.77874 8.1768 2.77879L8.24 2.77879H10.16L10.2232 2.77879C11.0742 2.77874 11.8059 2.7787 12.3931 2.85796C12.0418 1.96487 11.1744 1.33301 10.16 1.33301Z"
      fill="url(#paint0_linear_244_13703)"
    />
    <path
      d="M4.3999 7.59859C4.3999 5.78112 4.3999 4.87239 4.96226 4.30778C5.52461 3.74316 6.42971 3.74316 8.2399 3.74316H10.1599C11.9701 3.74316 12.8752 3.74316 13.4375 4.30778C13.9999 4.87239 13.9999 5.78112 13.9999 7.59859V10.8114C13.9999 12.6289 13.9999 13.5376 13.4375 14.1022C12.8752 14.6669 11.9701 14.6669 10.1599 14.6669H8.2399C6.42971 14.6669 5.52461 14.6669 4.96226 14.1022C4.3999 13.5376 4.3999 12.6289 4.3999 10.8114V7.59859Z"
      fill="url(#paint1_linear_244_13703)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_244_13703"
        x1="2"
        y1="7.2056"
        x2="12.3931"
        y2="7.2056"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#062236" />
        <stop offset="1" stop-color="#212528" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_244_13703"
        x1="4.3999"
        y1="9.20501"
        x2="13.9999"
        y2="9.20501"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#062236" />
        <stop offset="1" stop-color="#212528" />
      </linearGradient>
    </defs>
  </svg>
);

export default React.memo(Icon);
