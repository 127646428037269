import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQueryWithReauth } from 'store/api-base-query';
import { ApiRoutes } from 'enums';
import {
  CreatePropertyReq,
  EditPropertyReq,
  PropertiesRes,
  PropertyCountRes,
  PropertyRes
} from 'types';

export const propertiesApi = createApi({
  reducerPath: 'PropertiesApi',
  tagTypes: ['Properties'],
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    getProperties: builder.query<PropertiesRes, string>({
      query: queryParams => ({
        url: `${ApiRoutes.PROPERTY}?${queryParams}`,
        method: 'GET'
      }),

      providesTags: ['Properties']
    }),
    getActiveProperties: builder.query<PropertiesRes, string>({
      query: queryParams => ({
        url: `${ApiRoutes.PROPERTY}?${queryParams}`,
        method: 'GET'
      }),

      serializeQueryArgs: ({ endpointName }) => endpointName,

      merge: (currentCache, newItems) => {
        if (!newItems.metadata.hasPrev) {
          currentCache.properties = newItems.properties;
        } else {
          currentCache.properties.push(...newItems.properties);
        }
        currentCache.metadata = newItems.metadata;
      },

      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },

      providesTags: ['Properties']
    }),
    getPropertiesStatusCount: builder.query<PropertyCountRes, void>({
      query: () => ({
        url: ApiRoutes.PROPERTY_COUNTER_STATUS,
        method: 'GET'
      }),
      providesTags: ['Properties']
    }),
    getPropertyById: builder.query<PropertyRes, string>({
      query: id => ({
        url: `${ApiRoutes.PROPERTY}/${id}`,
        method: 'GET'
      }),
      providesTags: ['Properties']
    }),
    createProperty: builder.mutation<PropertyRes, CreatePropertyReq>({
      query: body => ({
        url: ApiRoutes.PROPERTY,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Properties']
    }),
    editProperty: builder.mutation<PropertyRes, { id: string; data: EditPropertyReq }>({
      query: ({ id, data }) => ({
        url: `${ApiRoutes.PROPERTY}/${id}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['Properties']
    }),
    deleteProperty: builder.mutation<PropertyRes, string>({
      query: id => ({
        url: `${ApiRoutes.PROPERTY}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Properties']
    })
  })
});

export const {
  useGetPropertiesQuery,
  useGetPropertiesStatusCountQuery,
  useGetPropertyByIdQuery,
  useGetActivePropertiesQuery,
  useCreatePropertyMutation,
  useEditPropertyMutation,
  useDeletePropertyMutation
} = propertiesApi;
