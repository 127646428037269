import { Box, Button, MenuItem, Pagination, Stack, Typography, styled } from '@mui/material';

export const StyledPagination = styled(Pagination)`
  .MuiButtonBase-root {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.6;
    border: none;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.palette.common.white};
    color: ${({ theme }) => theme.palette.common.black};
  }

  .MuiButtonBase-root :first-of-type {
    border: 1px solid ${({ theme }) => theme.palette.common.white};
  }

  .MuiButtonBase-root :last-of-type {
    color: ${({ theme }) => theme.palette.common.thunderCloud};
  }

  .MuiPaginationItem-root.Mui-selected {
    background-color: ${({ theme }) => theme.palette.common.disabled};

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.palette.common.disabled};
    }
  }

  .MuiPaginationItem-ellipsis {
    color: ${({ theme }) => theme.palette.common.black};
  }
`;

export const StyledPaginationStack = styled(Stack)<{
  isvisible: number;
}>`
  display: ${({ isvisible }) => (isvisible ? 'flex' : 'none')};
  margin-top: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StyledPaginationSelectStack = styled(Stack)`
  flex-direction: row;
  align-items: center;
`;

export const StyledTypography = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
  margin-right: 16px;
  color: ${({ theme }) => theme.palette.common.thunderCloud};
`;

export const StyledPopperButton = styled(Button)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.6;
  height: 32px;
  width: 92px;
  padding-left: 10px;
  border: 1px solid ${({ theme }) => theme.palette.common.northSeaBlue};
  border-radius: 8px;
  color: ${({ theme }) => theme.palette.common.text};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.palette.common.text};
  }
`;

export const StyledPopperDialog = styled(Box)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 8px;
  width: 92px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  height: 20px;
  justify-content: center;
  border: none;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.common.northSeaBlue};
  }
`;
