export default function FormHelperText() {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 4,
          marginLeft: 0,
          marginBottom: '-24px',
          fontSize: '12px'
        }
      }
    }
  };
}
