import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';

import { SVGIcon } from 'components';
import { useTheme } from 'hooks';

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label?: string;
  placeholder?: string;
  helperText?: React.ReactNode;
}

export default function RHFAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  name,
  label,
  placeholder,
  helperText,
  ...other
}: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const { control, setValue } = useFormContext();
  const { palette } = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          popupIcon={<SVGIcon name="chevronDown" width="13" height="8" />}
          clearIcon={<SVGIcon name="cancel" width="11" height="11" fill={palette.common.gold} />}
          {...field}
          onChange={(event, newValue) => setValue(name, newValue, { shouldValidate: true })}
          renderInput={params => (
            <TextField
              label={label}
              placeholder={placeholder}
              autoCapitalize="off"
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...params}
              inputProps={{ ...params.inputProps, 'data-testid': name }}
              sx={{
                '& fieldset': {
                  borderColor: `${palette.common.disabled} !important`
                },
                '& .MuiAutocomplete-inputRoot': {
                  padding: '1px 65px 0 0 !important'
                },
                '& .MuiAutocomplete-popupIndicator': {
                  marginTop: '0 !important'
                },
                '& .MuiAutocomplete-clearIndicator': {
                  marginTop: '0 !important'
                },
                '& .Mui-disabled fieldset': {
                  borderColor: `${palette.common.disabled} !important`
                },
                input: {
                  height: '35px',
                  color: palette.common.black,
                  borderRadius: '10px',
                  padding: '2px 15px !important',
                  '&:-webkit-autofill': {
                    backgroundColor: `${palette.common.white} !important`,
                    boxShadow: `0 0 0 50px transparent inset !important`,
                    WebkitTextFillColor: `${palette.common.black} !important`
                  }
                },
                '::placeholder': {
                  color: palette.common.disabled
                }
              }}
            />
          )}
          {...other}
        />
      )}
    />
  );
}
