import { forwardRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { OutlinedInput, OutlinedInputProps, Stack } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import 'react-phone-number-input/style.css';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { CountryCode } from 'libphonenumber-js/types.cjs';

import { useTheme } from 'hooks';

const DEFAULT_COUNTRY: CountryCode = 'US';
const COUNTRIES: CountryCode[] = ['US'];

type Props = OutlinedInputProps & {
  name: string;
};

type CustomInputProps = {
  error: boolean;
};

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>((props, ref) => (
  <OutlinedInput inputRef={ref} {...props} />
));

export default function RHFPhone({ name, type, placeholder, ...other }: Props) {
  const { control } = useFormContext();
  const { palette } = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState: { error } }) => (
        <Stack pl="20px">
          <InputLabel htmlFor={name}>{other.label}</InputLabel>
          <PhoneInputWithCountry
            autoComplete="new-password"
            name={name}
            placeholder={placeholder}
            countries={COUNTRIES}
            defaultCountry={DEFAULT_COUNTRY}
            international
            limitMaxLength
            withCountryCallingCode
            countryCallingCodeEditable={false}
            country="US"
            inputComponent={CustomInput}
            error={!!error}
            sx={{
              marginLeft: '-55px',
              paddingLeft: '24px',
              height: '40px',
              '& fieldset': {
                borderColor: palette.common.disabled
              }
            }}
          />
          {error && (
            <FormHelperText sx={{ color: palette.common.error, fontSize: '12px', ml: '-20px' }}>
              {error.message}
            </FormHelperText>
          )}
        </Stack>
      )}
    />
  );
}
