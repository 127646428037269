import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PropertyStatuses } from 'enums';

export interface IPropertiesState {
  propertyStatus?: PropertyStatuses;
  searchParam: string;
  pageSize: number;
  page: number;
}

const DEFAULT_PAGE_SIZE: number = 15;
const DEFAULT_PAGE: number = 1;

const initialState: IPropertiesState = {
  propertyStatus: undefined,
  searchParam: '',
  pageSize: DEFAULT_PAGE_SIZE,
  page: DEFAULT_PAGE
};

export const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setPropertiesData: (state: IPropertiesState, action: PayloadAction<IPropertiesState>) => ({
      ...state,
      ...action.payload
    }),
    setSearchParam(state: IPropertiesState, { payload }: PayloadAction<string>) {
      state.searchParam = payload;
    },
    setPropertyStatus(state: IPropertiesState, { payload }: PayloadAction<PropertyStatuses>) {
      state.propertyStatus = payload;
    },
    setPageSize(state: IPropertiesState, { payload }: PayloadAction<number>) {
      state.pageSize = payload;
    },
    setPage(state: IPropertiesState, { payload }: PayloadAction<number>) {
      state.page = payload;
    },
    clearPage(state: IPropertiesState, { payload }: PayloadAction<void>) {
      state.page = initialState.page;
    },
    clearPropertiesData: (state: IPropertiesState) => ({ ...initialState })
  }
});

export const {
  setPropertiesData,
  clearPropertiesData,
  setSearchParam,
  setPropertyStatus,
  setPageSize,
  setPage,
  clearPage
} = propertiesSlice.actions;

export default propertiesSlice.reducer;
