import { ElementType, Suspense } from 'react';
import { Loader } from 'components';

export const Loadable = (Component: ElementType) =>
  function (props: any) {
    return (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    );
  };
