import { Avatar, Backdrop, Box, Button, MenuItem, Popper, Stack, styled } from '@mui/material';

export const StyledBackdrop = styled(Backdrop)`
  position: absolute;
  width: 100%;
  height: calc(100vh + 111px);
  top: 80px;
  left: 1px;
  backdrop-filter: blur(10px);
`;

export const StyledProfileStack = styled(Stack)`
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const StyledButton = styled(Button)`
  min-width: 40px;
  min-height: 40px;
`;

export const StyledAvatar = styled(Avatar)`
width: 46px,
height: 46px;
margin-right: 12px;
background: ${({ theme }) => theme.palette.common.goldGradient};
`;

export const StyledPopperDialog = styled(Box)`
  background-color: ${({ theme }) => theme.palette.common.white};
  margin-top: 21px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const StyledPopper = styled(Popper)`
  width: 100%;
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  padding-left: 20px;
  justify-content: flex-start;
  height: 50px;
  border: none;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.common.northSeaBlue};
  }
`;
