import { ReactNode } from 'react';
import { SxProps } from '@mui/system';

import { StyledBox } from './styles';

type Props = {
  children?: ReactNode;
  sx?: SxProps;
};

export function MainPageWrapper({ children, sx }: Props): JSX.Element {
  return <StyledBox sx={sx}>{children}</StyledBox>;
}
