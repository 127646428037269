import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.33331 4.63333C1.33331 4.04497 1.33331 3.7508 1.37955 3.50575C1.58307 2.42704 2.42685 1.58326 3.50557 1.37973C3.75061 1.3335 4.04479 1.3335 4.63314 1.3335C4.89093 1.3335 5.01982 1.3335 5.14369 1.34508C5.67775 1.39502 6.18433 1.60485 6.59727 1.94717C6.69306 2.02657 6.7842 2.11771 6.96648 2.29999L7.33331 2.66683C7.87717 3.21068 8.14909 3.48261 8.47472 3.66378C8.65361 3.7633 8.84335 3.8419 9.04021 3.89801C9.39858 4.00016 9.78314 4.00016 10.5523 4.00016H10.8014C12.5563 4.00016 13.4337 4.00016 14.0041 4.51314C14.0566 4.56033 14.1065 4.61025 14.1537 4.66272C14.6666 5.23306 14.6666 6.11052 14.6666 7.86543V9.3335C14.6666 11.8477 14.6666 13.1047 13.8856 13.8858C13.1045 14.6668 11.8475 14.6668 9.33331 14.6668H6.66665C4.15249 14.6668 2.89541 14.6668 2.11436 13.8858C1.33331 13.1047 1.33331 11.8477 1.33331 9.3335V4.63333ZM8.66665 6.16683C8.3905 6.16683 8.16665 6.39069 8.16665 6.66683C8.16665 6.94297 8.3905 7.16683 8.66665 7.16683H12C12.2761 7.16683 12.5 6.94297 12.5 6.66683C12.5 6.39069 12.2761 6.16683 12 6.16683H8.66665Z"
      fill="url(#paint0_linear_133_13069)"
    />
    <path
      d="M10.8556 2.00016C11.0995 2.00016 11.2214 2.00016 11.3237 2.01412C12.0028 2.10673 12.5429 2.64225 12.6666 3.3335C12.568 3.31071 12.4656 3.29309 12.3592 3.27924C11.933 3.22373 11.3943 3.22374 10.726 3.22375L10.5031 3.22375C9.87515 3.22375 9.63725 3.22012 9.42026 3.15605C9.2943 3.11886 9.17289 3.06676 9.05843 3.0008C8.86126 2.88717 8.69056 2.71549 8.24656 2.25558L7.99998 2.00016H10.8556Z"
      fill="url(#paint1_linear_133_13069)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_133_13069"
        x1="1.33331"
        y1="8.00016"
        x2="14.6666"
        y2="8.00016"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#062236" />
        <stop offset="1" stop-color="#212528" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_133_13069"
        x1="1.33331"
        y1="8.00016"
        x2="14.6666"
        y2="8.00016"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#062236" />
        <stop offset="1" stop-color="#212528" />
      </linearGradient>
    </defs>
  </svg>
);

export default React.memo(Icon);
