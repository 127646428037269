enum TransUnionResult {
  SYSTEM_ERROR = 'SystemError',
  INVALID = 'InvalidInquiry',
  COMPLETED = 'Completed',
  ALREADY_VERIFIED = 'TransUnionIdentityAlreadyVerified',
  QUESTIONED = 'ApplicantQuestioned',
  PASSED = 'Passed',
  FAIL = 'Fail'
}

export { TransUnionResult };
