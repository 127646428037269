import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'store/api-base-query';
import { ApiRoutes } from 'enums';
import {
  ITransUnionIdentityEvaluateBody,
  ITransUnionIdentityEvaluateResponse,
  ITransUnionVerifyRes,
  TransUnionIdentityEvaluateResponse,
  TransUnionIdentityVerifyResponse
} from 'types/api/trans-union';
import { transformIdentityResponse } from './transform-response';

export const transUnionApi = createApi({
  reducerPath: 'TransUnionApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    sendIdentityVerify: builder.mutation<ITransUnionVerifyRes, void>({
      query: body => ({
        url: ApiRoutes.TRANS_UNION_IDENTITY_VERIFY,
        method: 'POST',
        body
      }),
      transformResponse: (response: TransUnionIdentityVerifyResponse) =>
        transformIdentityResponse(response)
    }),
    sendIdentityEvaluate: builder.mutation<
      ITransUnionIdentityEvaluateResponse,
      ITransUnionIdentityEvaluateBody
    >({
      query: body => ({
        url: ApiRoutes.TRANS_UNION_IDENTITY_EVALUATE,
        method: 'POST',
        body
      }),
      transformResponse: (response: TransUnionIdentityEvaluateResponse) => {
        const baseResponse = transformIdentityResponse(response);

        return {
          ...baseResponse,
          result: response.result
        };
      }
    })
  })
});

export const { useSendIdentityVerifyMutation, useSendIdentityEvaluateMutation } = transUnionApi;
