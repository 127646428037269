import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CheckTypes } from 'enums';

type Checks = {
  applicationId: string;
  checksTypes: CheckTypes[];
  isBackgroundChecks: boolean;
  isVerifyIdentityModal: boolean;
  isTransUnionVerifyIdentityModal: boolean;
  isBankIncomeAssetsModal: boolean;
  isPayrollIncomeModal: boolean;
  isPayscoreModal: boolean;
  isShareableModal: boolean;
  isProcessed: boolean;
};

const initialState: Checks = {
  applicationId: '',
  checksTypes: [],
  isBackgroundChecks: false,
  isVerifyIdentityModal: false,
  isTransUnionVerifyIdentityModal: false,
  isBankIncomeAssetsModal: false,
  isPayrollIncomeModal: false,
  isPayscoreModal: false,
  isShareableModal: false,
  isProcessed: false
};

export const checksSlice = createSlice({
  name: 'checks',
  initialState,
  reducers: {
    updateChecksData: (state: Checks, action: PayloadAction<Checks>) => ({
      ...state,
      ...action.payload
    }),
    updateApplicationId(state: Checks, { payload }: PayloadAction<string>) {
      state.applicationId = payload;
    },
    updateChecksTypes(state: Checks, { payload }: PayloadAction<CheckTypes[]>) {
      state.checksTypes = payload;
    },
    removeChecksTypes(state: Checks, { payload }: PayloadAction<CheckTypes[]>) {
      state.checksTypes = state.checksTypes.filter(type => !payload.includes(type));
    },
    updateIsBackgroundChecks(state: Checks, { payload }: PayloadAction<boolean>) {
      state.isBackgroundChecks = payload;
    },
    updateIsVerifyIdentityModal(state: Checks, { payload }: PayloadAction<boolean>) {
      state.isVerifyIdentityModal = payload;
    },
    updateIsTransUnionVerifyIdentityModal(state: Checks, { payload }: PayloadAction<boolean>) {
      state.isTransUnionVerifyIdentityModal = payload;
    },
    updateIsShareableModal(state: Checks, { payload }: PayloadAction<boolean>) {
      state.isShareableModal = payload;
    },
    updateIsBankIncomeAssetsModal(state: Checks, { payload }: PayloadAction<boolean>) {
      state.isBankIncomeAssetsModal = payload;
    },
    updateIsPayrollIncomeModal(state: Checks, { payload }: PayloadAction<boolean>) {
      state.isPayrollIncomeModal = payload;
    },
    updateIsPayscoreModal(state: Checks, { payload }: PayloadAction<boolean>) {
      state.isPayscoreModal = payload;
    },
    updateIsProcessed(state: Checks, { payload }: PayloadAction<boolean>) {
      state.isProcessed = payload;
    },
    clearModalsState: (state: Checks) => ({
      ...state,
      isVerifyIdentityModal: false,
      isTransUnionVerifyIdentityModal: false,
      isBankIncomeAssetsModal: false,
      isPayrollIncomeModal: false,
      isPayscoreModal: false,
      isShareableModal: false
    }),
    clearChecksData: (state: Checks) => ({ ...initialState })
  }
});

export const {
  updateChecksData,
  updateApplicationId,
  updateChecksTypes,
  removeChecksTypes,
  updateIsBackgroundChecks,
  updateIsVerifyIdentityModal,
  updateIsTransUnionVerifyIdentityModal,
  updateIsBankIncomeAssetsModal,
  updateIsPayrollIncomeModal,
  updateIsPayscoreModal,
  updateIsShareableModal,
  updateIsProcessed,
  clearModalsState,
  clearChecksData
} = checksSlice.actions;

export default checksSlice.reducer;
