export function getFormattedDate(date?: string | null): string | null {
  const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' };

  if (date) {
    const inputDate = new Date(date);

    return inputDate.toLocaleDateString('en-US', options);
  } else {
    return null;
  }
}
