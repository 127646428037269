import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from 'store/api-base-query';
import { ApiRoutes } from 'enums';
import { propertiesApi } from 'store/properties';
import { dashboardApi } from 'store/dashboard';
import { profileApi } from 'store/profile';

import {
  IChangePasswordRequest,
  IChangePasswordResponse,
  ILoginRequest,
  ILoginResponse,
  IRefreshAccessRequest,
  IRefreshAccessResponse,
  IRequestOtpRequest,
  IRequestOtpResponse,
  ISignUpRequest,
  ISignUpResponse,
  ISignUpSendVerifyEmail,
  ISignUpVerifyOtpRequest,
  ISignUpVerifyOtpResponse,
  IVerifyOtpRequest,
  IVerifyOtpResponse
} from './types';

export const authApi = createApi({
  reducerPath: 'AuthApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    signUp: builder.mutation<ISignUpResponse, ISignUpRequest>({
      query: body => ({
        url: ApiRoutes.SIGN_UP,
        method: 'POST',
        body
      })
    }),
    logIn: builder.mutation<ILoginResponse, ILoginRequest>({
      query: body => ({
        url: ApiRoutes.LOG_IN,
        method: 'POST',
        body
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }): Promise<void> {
        try {
          await queryFulfilled;
          dispatch(propertiesApi.util.invalidateTags(['Properties']));
          dispatch(dashboardApi.util.invalidateTags(['Applications']));
          dispatch(profileApi.util.invalidateTags(['Profile']));
        } catch (error: any) {
          return error;
        }
      }
    }),
    signUpVerifyEmail: builder.mutation<ISignUpSendVerifyEmail, void>({
      query: body => ({
        url: ApiRoutes.SIGN_UP_VERIFY_EMAIL,
        method: 'POST',
        body
      })
    }),
    signUpVerifyOtp: builder.mutation<ISignUpVerifyOtpResponse, ISignUpVerifyOtpRequest>({
      query: body => ({
        url: ApiRoutes.SIGN_UP_VERIFY_OTP,
        method: 'PATCH',
        body
      })
    }),
    refreshToken: builder.mutation<IRefreshAccessResponse, IRefreshAccessRequest>({
      query: body => ({
        url: ApiRoutes.REFRESH_ACCESS,
        method: 'POST',
        body
      })
    }),
    resetPasswordRequestOtp: builder.mutation<IRequestOtpResponse, IRequestOtpRequest>({
      query: body => ({
        url: ApiRoutes.RESET_PASSWORD_REQUEST_OTP,
        method: 'POST',
        body
      })
    }),
    resetPasswordVerifyOtp: builder.mutation<IVerifyOtpResponse, IVerifyOtpRequest>({
      query: body => ({
        url: ApiRoutes.RESET_PASSWORD_VERIFY_OTP,
        method: 'POST',
        body
      })
    }),
    changePassword: builder.mutation<IChangePasswordResponse, IChangePasswordRequest>({
      query: body => ({
        url: ApiRoutes.RESET_PASSWORD,
        method: 'PATCH',
        body
      })
    })
  })
});

export const {
  useSignUpMutation,
  useLogInMutation,
  useSignUpVerifyEmailMutation,
  useSignUpVerifyOtpMutation,
  useRefreshTokenMutation,
  useResetPasswordRequestOtpMutation,
  useResetPasswordVerifyOtpMutation,
  useChangePasswordMutation
} = authApi;
