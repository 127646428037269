import { Dialog, DialogContent, Stack, styled } from '@mui/material';
import { Colors } from 'enums';

export const StyledDialog = styled(Dialog)`
  backdrop-filter: blur(5px);
`;

export const StyledDialogContent = styled(DialogContent)`
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.common.black};
  border-radius: 20px;
  min-width: 480px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  overflow: hidden;
`;

export const StyledModalStack = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 20px;
`;

export const IconContainer = styled('div')`
  border-radius: 8px;
  padding: 10px;
  background-color: ${Colors.LIGHT_ROSE};
  display: flex;
  justify-content: center;
  align-items: center;
`;
