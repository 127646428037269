import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse cx="16.0001" cy="8.00008" rx="5.33333" ry="5.33333" fill="white" />
    <ellipse opacity="0.5" cx="16.0001" cy="22.6666" rx="9.33333" ry="5.33333" fill="white" />
  </svg>
);

export default React.memo(Icon);
