import { ApplicationStatuses } from 'enums';

import { ApplicationsCountRes } from 'types';

export const statusToCountKey: Record<ApplicationStatuses, keyof ApplicationsCountRes> = {
  [ApplicationStatuses.NEW]: 'new',
  [ApplicationStatuses.PENDING]: 'pending',
  [ApplicationStatuses.PAID]: 'paid',
  [ApplicationStatuses.EDIT_REQUEST]: 'edit-request',
  [ApplicationStatuses.COMPLETED]: 'completed',
  [ApplicationStatuses.APPROVED]: 'approved',
  [ApplicationStatuses.CANCELED]: 'canceled',
  [ApplicationStatuses.REJECTED]: 'rejected',
  [ApplicationStatuses.USER_ACTION_PENDING]: 'pending'
};
