import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQueryWithReauth } from 'store/api-base-query';
import { ApiRoutes } from 'enums';
import {
  Application,
  ApplicationsCountRes,
  ApplicationsRes,
  CreateApplicationReq,
  QuestionReq
} from 'types';
import { AdverseLetterReq } from 'types/api/adverse';

export const dashboardApi = createApi({
  reducerPath: 'DashboardApi',
  tagTypes: ['Applications'],
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    getApplications: builder.query<ApplicationsRes, string>({
      query: queryParams => ({
        url: `${ApiRoutes.APPLICATION}?${queryParams}`,
        method: 'GET'
      }),
      providesTags: ['Applications']
    }),
    getApplicationById: builder.query<Application, string>({
      query: id => ({
        url: `${ApiRoutes.APPLICATION}/${id}`,
        method: 'GET'
      }),
      providesTags: ['Applications']
    }),
    getApplicationsStatusCount: builder.query<ApplicationsCountRes, void>({
      query: () => ({
        url: ApiRoutes.APPLICATION_COUNTER_STATUS,
        method: 'GET'
      }),
      providesTags: ['Applications']
    }),
    createApplication: builder.mutation<Application, CreateApplicationReq>({
      query: body => ({
        url: ApiRoutes.APPLICATION,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Applications']
    }),
    stripePay: builder.mutation<{ link: string }, { id: string }>({
      query: ({ id }) => ({
        url: `${ApiRoutes.APPLICATION}/${id}${ApiRoutes.PAY}`,
        method: 'POST'
      }),
      invalidatesTags: ['Applications']
    }),
    deleteApplication: builder.mutation<Application, string>({
      query: id => ({
        url: `${ApiRoutes.APPLICATION}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Applications']
    }),
    acceptApplication: builder.mutation<Application, { id: string }>({
      query: ({ id }) => ({
        url: `${ApiRoutes.APPLICATION}/${id}${ApiRoutes.ACCEPT}`,
        method: 'POST'
      }),
      invalidatesTags: ['Applications']
    }),
    shareApplication: builder.mutation<Application, { id: string }>({
      query: ({ id }) => ({
        url: `${ApiRoutes.APPLICATION}/${id}${ApiRoutes.SHARE}`,
        method: 'POST'
      }),
      invalidatesTags: ['Applications']
    }),
    rejectApplication: builder.mutation<Application, { id: string }>({
      query: ({ id }) => ({
        url: `${ApiRoutes.APPLICATION}/${id}${ApiRoutes.REJECT}`,
        method: 'POST'
      }),
      invalidatesTags: ['Applications']
    }),
    getTemplate: builder.query<Blob, void>({
      query: () => ({
        url: ApiRoutes.BULK_GET_TEMPLATE,
        method: 'GET',
        responseHandler: async response => await response.blob(),
        cache: 'no-cache'
      })
    }),
    uploadFile: builder.mutation<Application, FormData>({
      query: body => ({
        url: ApiRoutes.BULK_UPLOAD_FILE,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Applications']
    }),
    sendLetter: builder.mutation<void, { id: string; body: AdverseLetterReq }>({
      query: ({ id, body }) => ({
        url: `${ApiRoutes.APPLICATION}/${id}${ApiRoutes.SEND_CHECKS_LETTER}`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Applications']
    }),
    requestEdits: builder.mutation<void, { id: string; body: QuestionReq }>({
      query: ({ id, body }) => ({
        url: `${ApiRoutes.APPLICATION}/${id}${ApiRoutes.REQUEST_EDITS}`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Applications']
    })
  })
});

export const {
  useGetApplicationsQuery,
  useGetApplicationByIdQuery,
  useGetApplicationsStatusCountQuery,
  useCreateApplicationMutation,
  useRejectApplicationMutation,
  useAcceptApplicationMutation,
  useGetTemplateQuery,
  useDeleteApplicationMutation,
  useShareApplicationMutation,
  useUploadFileMutation,
  useStripePayMutation,
  useSendLetterMutation,
  useRequestEditsMutation
} = dashboardApi;
