import { Stack, SxProps, Typography } from '@mui/material';
import { t } from 'i18next';

import { RHFCheckbox } from 'components/hook-form';

import { StyledCheckboxStack, StyledLink, StyledLinkStack } from './styles';

type Props = {
  checkboxName: string;
  sx?: SxProps;
  togglePrivacyModal: () => void;
  toggleTermsModal: () => void;
};

export function PrivacyPolicyTermsStack({
  checkboxName,
  sx,
  togglePrivacyModal,
  toggleTermsModal
}: Props): JSX.Element {
  return (
    <Stack sx={sx}>
      <StyledCheckboxStack>
        <RHFCheckbox name={checkboxName} label="" sx={{ mr: '3px' }}>
          <StyledLinkStack>
            <Typography variant="body1">{t('common.termsPolicyCheckbox')}</Typography>

            <StyledLink onClick={togglePrivacyModal} variant="body1">
              {t('common.privacyPolicy')}
            </StyledLink>

            <Typography variant="body1" marginLeft="3px">
              {t('common.and')}
            </Typography>

            <StyledLink onClick={toggleTermsModal} variant="body1">
              {t('common.termsConditions')}
            </StyledLink>
          </StyledLinkStack>
        </RHFCheckbox>
      </StyledCheckboxStack>
    </Stack>
  );
}
