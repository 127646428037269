enum SignUpFields {
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PASSWORD = 'password',
  REPEAT_PASSWORD = 'repeatPassword',
  TERMS_PRIVACY = 'termsPrivacyCheckbox'
}

export { SignUpFields };
