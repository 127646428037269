enum ApplicationFields {
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phoneNumber',
  CHECK_TYPE = 'checkType',
  PROPERTY_ID = 'propertyId',
  TERMS_CONDITIONS = 'termsConditionsCheckbox'
}

export { ApplicationFields };
