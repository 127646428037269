import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material';
import { Colors } from 'enums';
import { ButtonPresets } from './button';

export const StyledButton = styled(LoadingButton)<{
  preset: ButtonPresets;
}>`
  color: ${Colors.WHITE};
  border-radius: 10px;
  height: 40px;
  white-space: nowrap;

  ${({ preset }) => {
    switch (preset) {
      case 'primary':
        return `
        
        background: ${Colors.DARK_BLUE_GRADIENT};
      
        &:disabled {
          background: ${Colors.THUNDER_CLOUD};
        }
      
        &:hover,
        &:focus {
          color: ${Colors.WHITE};
        }
        `;

      case 'secondary':
        return `
        color: ${Colors.BLACK};
        background: transparent;
        border: 1px solid ${Colors.DISABLED};
      
        &:disabled {
          background: ${Colors.THUNDER_CLOUD};
        }
      
        &:hover,
        &:focus {
          color: ${Colors.BLACK};
        }
        `;

      case 'start':
        return `
   
       
        color: ${Colors.START_BTN};
      
        &:disabled {
          background: ${Colors.THUNDER_CLOUD};
        }
      
        &:hover,
        &:focus {
        text-decoration: underline;
        color: ${Colors.START_BTN};
        }
        `;

      case 'approve':
        return `
     
          color: ${Colors.WHITE};
          background-color: ${Colors.APPROVE};
        
          &:disabled {
            background: ${Colors.THUNDER_CLOUD};
          }
        
          &:hover,
          &:focus {
          color: ${Colors.WHITE};
          background-color: ${Colors.APPROVE};
          }
          `;

      case 'reject':
        return `
        background: ${Colors.BUTTON_REJECT};
        color: ${Colors.WHITE};
      
        &:disabled {
          background: ${Colors.THUNDER_CLOUD};
        }
      
        &:hover {
          background: ${Colors.BUTTON_REJECT};
          color: ${Colors.WHITE};
        }
        `;

      case 'cancel':
        return `
        white-space: nowrap;
        background: none;
        color: ${Colors.BLACK};
        border: 1px solid ${Colors.BLACK};


        
          &:hover, focus {
            background: none;
            color: ${Colors.BLACK};
          }
          `;

      default:
        return `
         
        background: ${Colors.DARK_BLUE_GRADIENT};
        color: ${Colors.WHITE};
      
        &:disabled {
          background: ${Colors.THUNDER_CLOUD};
          color: ${Colors.WHITE};
        }
      
        &:hover,
        &:focus {
          color: ${Colors.WHITE};
        }
        `;
    }
  }};

  &:disabled {
    background: ${Colors.DISABLED};
  }
`;
