import { Typography } from '@mui/material';
import { t } from 'i18next';

import { ApplicationStatuses, Colors } from 'enums';
import { useMemo, useCallback } from 'hooks';

import { StyledButton, StyledStack, Badge } from './styles';
import { ApplicationsCountRes } from 'types';
import { statusToCountKey } from './handlers';

type Props = {
  handleClick: (id?: ApplicationStatuses) => void;
  count: ApplicationsCountRes | undefined;
  selectedStatus?: ApplicationStatuses;
};

export function SelectStatus({ handleClick, count, selectedStatus }: Props): JSX.Element {
  const STATUSES: ApplicationStatuses[] = useMemo(
    () =>
      Object.values(ApplicationStatuses).filter(
        el => el !== ApplicationStatuses.USER_ACTION_PENDING
      ),
    []
  );

  const renderStatuses = useCallback(
    () => (
      <>
        <StyledButton selected={!selectedStatus} onClick={() => handleClick()}>
          <Typography variant="body2" whiteSpace="nowrap">
            {t('dashboardPage.requestTypeAll')}
          </Typography>
          {count && (
            <Badge selected={!selectedStatus}>
              <Typography variant="caption" color={Colors.WHITE}>
                {count?.all}
              </Typography>
            </Badge>
          )}
        </StyledButton>
        {STATUSES.map(status => (
          <StyledButton
            key={status}
            selected={selectedStatus === status}
            onClick={() => handleClick(status)}
          >
            <Typography variant="body2">{t(`dashboardPage.requestType${status}`)}</Typography>
            {count && (
              <Badge selected={selectedStatus === status}>
                <Typography variant="caption" color="inherit" fontWeight="normal">
                  {count?.[statusToCountKey[status]]}
                </Typography>
              </Badge>
            )}
          </StyledButton>
        ))}
      </>
    ),
    [STATUSES, count, handleClick, selectedStatus]
  );

  return (
    <StyledStack flexDirection="row" marginBottom="8px">
      {renderStatuses()}
    </StyledStack>
  );
}
