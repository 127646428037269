import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.09327 13.3075C0.831188 13.0829 0.800837 12.6883 1.02548 12.4263L5.67684 6.99966L1.02548 1.57308C0.800838 1.311 0.831189 0.916434 1.09327 0.691796C1.35535 0.467157 1.74991 0.497508 1.97455 0.759587L6.97455 6.59292C7.17517 6.82698 7.17517 7.17235 6.97455 7.40641L1.97455 13.2397C1.74991 13.5018 1.35535 13.5322 1.09327 13.3075Z"
      fill="url(#paint0_linear_1977_1484)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1977_1484"
        x1="0.875"
        y1="6.99966"
        x2="7.12501"
        y2="6.99966"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
    </defs>
  </svg>
);

export default React.memo(Icon);
