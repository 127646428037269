import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66675 28.3333C2.11446 28.3333 1.66675 28.781 1.66675 29.3333C1.66675 29.8856 2.11446 30.3333 2.66675 30.3333H29.3334C29.8857 30.3333 30.3334 29.8856 30.3334 29.3333C30.3334 28.781 29.8857 28.3333 29.3334 28.3333H28.0001H24.6667H22.6667V21.3333C22.6667 18.8192 22.6667 17.5621 21.8857 16.781C21.1047 16 19.8476 16 17.3334 16H14.6667C12.1526 16 10.8955 16 10.1145 16.781C9.33341 17.5621 9.33341 18.8192 9.33341 21.3333V28.3333H7.33341H4.00008H2.66675ZM12.3334 20C12.3334 19.4477 12.7811 19 13.3334 19H18.6667C19.219 19 19.6667 19.4477 19.6667 20C19.6667 20.5523 19.219 21 18.6667 21H13.3334C12.7811 21 12.3334 20.5523 12.3334 20ZM12.3334 24C12.3334 23.4477 12.7811 23 13.3334 23H18.6667C19.219 23 19.6667 23.4477 19.6667 24C19.6667 24.5523 19.219 25 18.6667 25H13.3334C12.7811 25 12.3334 24.5523 12.3334 24Z"
      fill="white"
    />
    <g opacity="0.5">
      <path
        d="M10.6667 6C11.9237 6 12.5523 6 12.9428 6.39052C13.3333 6.78105 13.3333 7.40959 13.3333 8.66667L13.3333 11.0566C13.5424 11.1582 13.7242 11.2863 13.8856 11.4477C14.4244 11.9865 14.5915 12.7518 14.6434 14V16C12.1447 16 10.893 16.0024 10.1144 16.781C9.33333 17.5621 9.33333 18.8192 9.33333 21.3333V28.3333H4V16C4 13.4858 4 12.2288 4.78105 11.4477C4.9425 11.2863 5.1243 11.1582 5.33333 11.0566V8.66667C5.33333 7.40959 5.33333 6.78105 5.72386 6.39052C6.11438 6 6.74292 6 8 6H8.33333V4C8.33333 3.44772 8.78105 3 9.33333 3C9.88562 3 10.3333 3.44772 10.3333 4V6H10.6667Z"
        fill="white"
      />
      <path
        d="M27.5251 6.99568C27.0503 6.31045 26.2133 5.99658 24.5393 5.36885C21.2657 4.14122 19.6288 3.5274 18.4811 4.32279C17.3333 5.11818 17.3333 6.86632 17.3333 10.3626V16C19.8475 16 21.1046 16 21.8856 16.781C22.6667 17.5621 22.6667 18.8192 22.6667 21.3333V28.3333H28V10.3626C28 8.5748 28 7.68091 27.5251 6.99568Z"
        fill="white"
      />
    </g>
  </svg>
);

export default React.memo(Icon);
