import { Theme } from '@mui/material/styles';

export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          height: '54px',
          padding: '16px 20px',
          '&:hover': {
            border: `1px solid ${theme.palette.common.gold}`,
            borderRadius: '10px',
            '&& fieldset': {
              border: `1px solid ${theme.palette.common.gold}`,
              borderRadius: '10px'
            }
          },
          '&::placeholder': {
            color: theme.palette.common.statusActive
          },
          '.MuiOutlinedInput-notchedOutline': {
            color: theme.palette.common.black,
            borderWidth: '1px !important',
            borderColor: `${theme.palette.common.thunderCloud} !important`,
            borderRadius: '10px',
            pr: '18px'
          },
          '.MuiSelect-select': {
            color: theme.palette.common.black
          },
          '&.Mui-focused': {
            boxShadow: 'none',
            borderRadius: '10px',
            border: `1px solid ${theme.palette.common.gold}`
          }
        },
        icon: {
          position: 'absolute',
          right: '20px',
          top: '23px'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${theme.palette.common.northSeaBlue}`,
          padding: '16px',
          color: theme.palette.common.black,
          '&:hover': {
            opacity: 0.4
          },
          '&.Mui-selected': {
            color: theme.palette.common.thunderCloud
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          borderRadius: '10px',
          padding: 0,
          margin: 0
        }
      }
    }
  };
}
