import { TransUnionIdentityVerifyResponse } from 'types/api/trans-union';

export function transformIdentityResponse(response: TransUnionIdentityVerifyResponse) {
  return {
    isIdentityVerified: response.isIdentityVerified,
    questions: response.questions.map(question => ({
      questionKeyName: question.QuestionKeyName,
      questionDisplayName: question.QuestionDisplayName,
      type: question.Type,
      choices: question.Choices.map(choice => ({
        choiceKeyName: choice.ChoiceKeyName,
        choiceDisplayName: choice.ChoiceDisplayName
      }))
    }))
  };
}
