export const formatSSN = (value: string): string => {
  const cleanedValue = value.replace(/\D/g, '');
  let formattedValue = '';

  for (let i = 0; i < cleanedValue.length; i++) {
    if (i === 3 || i === 5) {
      formattedValue += '-';
    } else if (i === 9) {
      formattedValue += '-';
    }
    formattedValue += cleanedValue[i];
  }

  return formattedValue;
};
