import { IconButton, InputAdornment, SxProps } from '@mui/material';
import { t } from 'i18next';

import { SVGIcon } from 'components';
import { useTheme } from 'hooks';

import { StyledTextField } from './styles';

type Props = {
  value: string;
  sx?: SxProps;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleSearch?: () => void;
};

export function SearchField({
  value,
  sx,
  handleChange,
  handleKeyDown,
  handleSearch
}: Props): JSX.Element {
  const { palette } = useTheme();

  return (
    <StyledTextField
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      variant="standard"
      placeholder={t('searchField.placeholder')}
      sx={{
        ...sx,
        input: {
          border: `1px solid ${palette.common.disabled}`,
          color: palette.common.black,
          backgroundColor: `${palette.common.white} !important`,
          boxShadow: `0 0 0 50px ${palette.common.white} inset !important`,
          '&:-webkit-autofill': {
            backgroundColor: `${palette.common.white} !important`,
            boxShadow: `0 0 0 50px ${palette.common.white} inset !important`,
            WebkitTextFillColor: `${palette.common.black} !important`
          }
        }
      }}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              onClick={handleSearch}
              sx={{
                mr: '-63px',
                ml: '20px'
              }}
            >
              {<SVGIcon name="search" color={palette.common.disabled} />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
}
