import { Popper } from '@mui/material';
import { t } from 'i18next';

import { useCallback, useState } from 'hooks';
import { SVGIcon } from 'components';

import {
  StyledMenuItem,
  StyledPagination,
  StyledPaginationSelectStack,
  StyledPaginationStack,
  StyledPopperButton,
  StyledPopperDialog,
  StyledTypography
} from './styles';

type Props = {
  totalEntries: number;
  currentPage: number;
  pageSize: number;
  setCurrentPage: (page: number) => void;
  setPageSize: (page: number) => void;
};

const PAGE_SIZES = [10, 15, 20];

export function PaginationBar({
  totalEntries,
  currentPage,
  pageSize,
  setCurrentPage,
  setPageSize
}: Props): JSX.Element {
  const startIndex: number = (currentPage - 1) * pageSize + 1;
  const endIndex: number = Math.min(currentPage * pageSize, totalEntries);

  const getPageCount = (totalEntries: number): number =>
    totalEntries === 0 ? 0 : Math.ceil(totalEntries / pageSize);

  const handleChangePage = (e: any, newPage: number): void => {
    setCurrentPage(newPage);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handlePopperClick = (e: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleChoosePageSize = useCallback(
    (e: any): void => {
      setPageSize(e.currentTarget.value);
      setAnchorEl(anchorEl ? null : e.currentTarget);
    },
    [anchorEl, setPageSize]
  );

  const renderPopperItems = useCallback(
    () =>
      PAGE_SIZES.map((el, idx) => (
        <StyledMenuItem key={idx} onClick={handleChoosePageSize} value={el}>
          {el}
        </StyledMenuItem>
      )),
    [handleChoosePageSize]
  );

  return (
    <StyledPaginationStack isvisible={Number(totalEntries > PAGE_SIZES[0])}>
      <StyledTypography>{`${t('paginationBar.infoText5', {
        currentPage,
        totalPages: getPageCount(totalEntries)
      })}`}</StyledTypography>

      <StyledPagination
        count={getPageCount(totalEntries)}
        siblingCount={0}
        boundaryCount={1}
        page={currentPage}
        variant="combined"
        onChange={handleChangePage}
      />

      <StyledPaginationSelectStack>
        <StyledTypography>{`${t('paginationBar.infoText1')} ${startIndex} ${t('paginationBar.infoText2')} ${endIndex} ${t('paginationBar.infoText3')} ${totalEntries} ${t('paginationBar.infoText4')}`}</StyledTypography>

        <StyledPopperButton
          onClick={handlePopperClick}
          sx={{
            whiteSpace: 'nowrap'
          }}
        >
          {`${pageSize} ${t('paginationBar.pageSizeButton')}`}
          <SVGIcon name="chevronUp" />
        </StyledPopperButton>

        <Popper open={open} anchorEl={anchorEl} placement={'top'}>
          <StyledPopperDialog>{renderPopperItems()}</StyledPopperDialog>
        </Popper>
      </StyledPaginationSelectStack>
    </StyledPaginationStack>
  );
}
