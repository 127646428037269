import React, { SVGProps } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = props => (
  <svg
    width="101"
    height="101"
    viewBox="0 0 101 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      d="M8.8335 33.8333V50.4999C8.8335 62.285 8.8335 68.1776 12.4947 71.8387C14.4795 73.8235 17.12 74.7323 20.9997 75.1485C21.3081 74.7322 21.5999 74.4043 21.8081 74.1753C22.2922 73.643 22.9059 73.0379 23.501 72.4513L29.7215 66.3173L32.0187 63.9902C33.5285 55.1463 41.2277 48.4166 50.5004 48.4166C59.7731 48.4166 67.4723 55.1463 68.9821 63.9902L71.2794 66.3173L77.5002 72.4516C78.0951 73.0382 78.7087 73.6431 79.1927 74.1753C79.4009 74.4043 79.6927 74.7321 80.0011 75.1484C83.8805 74.7323 86.521 73.8235 88.5057 71.8388C92.1668 68.1776 92.1668 62.285 92.1668 50.4999V33.8332C92.1668 22.0481 92.1668 16.1556 88.5057 12.4944C84.8445 8.83325 78.9519 8.83325 67.1668 8.83325H33.8335C22.0484 8.83325 16.1558 8.83325 12.4947 12.4944C8.8335 16.1556 8.8335 22.0481 8.8335 33.8333Z"
      fill="url(#paint0_linear_108_3795)"
    />
    <path
      d="M29.6665 36.9583C27.9406 36.9583 26.5415 38.3574 26.5415 40.0833C26.5415 41.8092 27.9406 43.2083 29.6665 43.2083H71.3332C73.0591 43.2083 74.4582 41.8092 74.4582 40.0833C74.4582 38.3574 73.0591 36.9583 71.3332 36.9583H29.6665Z"
      fill="url(#paint1_linear_108_3795)"
    />
    <path
      d="M34.8748 25.5C34.8748 23.7741 36.2739 22.375 37.9998 22.375H62.9998C64.7257 22.375 66.1248 23.7741 66.1248 25.5C66.1248 27.2259 64.7257 28.625 62.9998 28.625H37.9998C36.2739 28.625 34.8748 27.2259 34.8748 25.5Z"
      fill="url(#paint2_linear_108_3795)"
    />
    <path
      d="M38.0046 66.8227C38.0016 66.937 38 67.0517 38 67.1667C38 74.0703 43.5964 79.6667 50.5 79.6667C57.4036 79.6667 63 74.0703 63 67.1667C63 67.0517 62.9984 66.937 62.9954 66.8227C62.8131 60.0782 57.2885 54.6667 50.5 54.6667C44.0279 54.6667 38.7047 59.5855 38.0645 65.8887C38.0332 66.1968 38.0131 66.5083 38.0046 66.8227Z"
      fill="url(#paint3_linear_108_3795)"
    />
    <path
      d="M32.4821 72.3724L27.9801 76.8118C26.6298 78.1433 25.9547 78.8091 25.7211 79.3729C25.1887 80.6576 25.6443 82.0828 26.8035 82.7586C27.3121 83.0552 28.2297 83.1477 30.0647 83.3325C31.1008 83.4369 31.6189 83.489 32.0528 83.6474C33.0241 84.002 33.7798 84.7472 34.1394 85.705C34.3 86.1329 34.3529 86.6438 34.4588 87.6655C34.6462 89.475 34.7399 90.3798 35.0407 90.8814C35.726 92.0245 37.1713 92.4737 38.4742 91.9487C39.0459 91.7184 39.7211 91.0526 41.0714 89.7211L45.5731 85.2627C39.2711 83.551 34.2882 78.635 32.4821 72.3724Z"
      fill="url(#paint4_linear_108_3795)"
    />
    <path
      d="M55.4269 85.2627L59.9286 89.7211C61.2789 91.0526 61.9541 91.7184 62.5258 91.9487C63.8287 92.4737 65.2739 92.0245 65.9593 90.8814C66.2601 90.3798 66.3538 89.475 66.5412 87.6655C66.6471 86.6438 66.7 86.1329 66.8606 85.705C67.2202 84.7472 67.9759 84.002 68.9472 83.6474C69.3811 83.489 69.8992 83.4369 70.9353 83.3325C72.7703 83.1477 73.6879 83.0552 74.1965 82.7586C75.3557 82.0828 75.8113 80.6576 75.2789 79.3729C75.0453 78.8091 74.3702 78.1433 73.0199 76.8118L68.5179 72.3724C66.7118 78.635 61.7289 83.551 55.4269 85.2627Z"
      fill="url(#paint5_linear_108_3795)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_108_3795"
        x1="50.5002"
        y1="8.83325"
        x2="50.5002"
        y2="75.1485"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_108_3795"
        x1="50.4998"
        y1="22.375"
        x2="50.4998"
        y2="43.2083"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_108_3795"
        x1="50.4998"
        y1="22.375"
        x2="50.4998"
        y2="43.2083"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_108_3795"
        x1="50.5"
        y1="54.6667"
        x2="50.5"
        y2="92.1667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_108_3795"
        x1="50.5"
        y1="54.6667"
        x2="50.5"
        y2="92.1667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_108_3795"
        x1="50.5"
        y1="54.6667"
        x2="50.5"
        y2="92.1667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEC093" />
        <stop offset="1" stopColor="#C5AB83" />
      </linearGradient>
    </defs>
  </svg>
);

export default React.memo(Icon);
