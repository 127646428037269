import { AccordionDetails, AccordionSummary, styled } from '@mui/material';

export const StyledAccordionSummary = styled(AccordionSummary)`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin-left: 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.disabled};

  h2,
  h3 {
    margin: 0;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  border-top: none;
  padding: 0;
  padding-top: 16px;
`;
